import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

// const formatSpread = (spread) => {
//     if(spread > 0){
//         return "+" + spread;
//     }else{
//         return spread.toString();
//     }
// }

const DateValue = ({ date, dateOnly }) => {
    if (!date) {
        return <React.Fragment> --</React.Fragment>;
    }
    let mDate = moment(date);
    let daysOut = mDate.diff(moment(), 'days');
    let useCloseInFormat = daysOut < 7 && daysOut > -1;
    let timeFormat = dateOnly ? '' : ' h:mm A';
    let formmattedDate = useCloseInFormat
        ? mDate.format(`ddd ${timeFormat}`)
        : mDate.format(`ddd (M/D) ${timeFormat}`);
    return <React.Fragment>{formmattedDate}</React.Fragment>;
};

export default DateValue;
