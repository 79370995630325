import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import green from '@material-ui/core/colors/green';
//import { GET_WAGERS } from '../Query'
import { UPDATE_META } from '../Mutation';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing.unit,
    },
});

const radioControlStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
});

export const TVRadioControl = withStyles(radioControlStyles)(({ classes, tv, handleChange }) => {
    //const { classes } = this.props;
    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                    row
                    aria-label="tv"
                    name="tv"
                    className={classes.group}
                    value={tv}
                    onChange={handleChange}
                >
                    <FormControlLabel value="" control={<Radio />} label="None" />
                    <FormControlLabel value="Left" control={<Radio />} label="Left" />
                    <FormControlLabel value="Center" control={<Radio />} label="Center" />
                    <FormControlLabel value="Right" control={<Radio />} label="Right" />
                </RadioGroup>
            </FormControl>
        </div>
    );
});

class EditTV extends Component {
    constructor(props) {
        super(props);

        let { tv } = this.props;
        this.state = {
            openSnack: false,
            tv: tv || '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event, updateMeta) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
        this.saveMetaTv(event, updateMeta, value);
    }

    handleSnackClose = (event, reason) => {
        this.setState({ openSnack: false });
    };
    // we'll do this async for now we so don't have a weird ui experience
    // look into optimistic response as well
    saveMetaTv = (e, updateFn, value) => {
        e.preventDefault();
        let { eid } = this.props;
        let qParams = {
            variables: {
                eid,
                tv: value,
            },
        };
        updateFn(qParams);
    };

    render() {
        let { classes } = this.props;
        let { tv } = this.state;
        let { eid } = this.props;
        return (
            <Mutation
                mutation={UPDATE_META}
                key={eid}
                update={(cache, { data: { updateMeta } }) => {
                    // here is where we could update cache if necessary?
                    this.setState({ openSnack: true });
                }}
            >
                {(updateMeta, { loading, error }) => (
                    <div>
                        {loading && <p>Loading...</p>}
                        {error && <p>Error :( Please try again</p>}
                        <TVRadioControl
                            tv={tv}
                            handleChange={(e) => this.handleInputChange(e, updateMeta)}
                        />
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={this.state.openSnack}
                            onClose={this.handleSnackClose}
                            autoHideDuration={1500}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">TV Change Saved</span>}
                        />
                    </div>
                )}
            </Mutation>
        );
    }
}

export default withStyles(styles, { withTheme: true })(EditTV);
