import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import BookLogoLink from '../Util/BookLogoLink';
import { filter } from 'graphql-anywhere';
import { TEAM_INPUT } from '../Fragment/Team';
import { ButtonSpread } from './ButtonSpread';
import { ButtonTeaser } from './ButtonTeaser';
import classNames from 'classnames';

const styles = (theme) => ({
    listItemRoot: {
        display: 'flex',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    listItemText: {
        flex: 'unset',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
    },
});

export const BookLines = withStyles(styles, { withTheme: true })((props) => {
    let {
        length,
        index,
        bookLine,
        classes,
        showSpreads,
        showTotals,
        onStraightClick,
        onTeaserClick,
        ...rest
    } = props;

    const decorateSelection = (type, selection) => {
        let { value, teasedValue, teaserAmt, partid, odds, ...pickProps } = selection;
        let adjValue = teasedValue != undefined ? teasedValue : value;
        let { awayTeam, date, eid, homeTeam } = rest;
        let pick = {
            ...pickProps,
            awayTeam: filter(TEAM_INPUT, awayTeam),
            homeTeam: filter(TEAM_INPUT, homeTeam),
            date,
            eid,
            //lineid,
            selectedPartId: partid,
            adjValue,
        };
        pick.againstPartId = getAgainstPartId(pick);
        pick.lineData = {
            partid,
            value,
            odds,
            eid: pick.eid,
            paid: pick.paid,
            lineid: pick.lineid,
            type: pick.type,
            subType: pick.subType,
        };
        let decoratedSelection = {
            paid: pick.paid,
            odds: pick.lineData.odds,
            book: bookLine.book,
            type: type,
            teaserAmt,
            picks: [pick],
        };

        return decoratedSelection;
    };

    const onStraightClickDecorated = (selection) => {
        let straightSelection = decorateSelection('S', selection);
        onStraightClick(straightSelection);
    };

    const onTeaserClickDecorated = (selection) => {
        let teaserSelection = decorateSelection('T', selection);
        onTeaserClick(teaserSelection);
    };

    const getAgainstPartId = ({ selectedPartId, type, subType }) => {
        let againstPartId = 0;
        if (type == 'total') {
            if (subType === 'over') {
                againstPartId = bookLine.teamsLine.under.partid;
            } else {
                againstPartId = bookLine.teamsLine.over.partid;
            }
        } else {
            //spread
            if (bookLine.teamsLine.homeSpread.partid === selectedPartId) {
                againstPartId = bookLine.teamsLine.awaySpread.partid;
            } else {
                againstPartId = bookLine.teamsLine.homeSpread.partid;
            }
        }
        return againstPartId;
    };
    // this isn't quite right b/c we shouldn't highlight the whole row, just the side with the wong
    const listItemClasses = classNames({
        [classes.listItemRoot]: true,
    });

    // todo: make the "no line" scenario look slightly better (i.e show booklogolink but with a "no line" indicator)
    // divider={index < length - 1}
    //classes={{ root: classes.listItemRoot }}
    return (
        <ListItem divider={true} dense disableGutters className={listItemClasses}>
            {bookLine.teamsLine && (
                <React.Fragment>
                    {showSpreads && (
                        <React.Fragment>
                            <ButtonSpread
                                onClick={onStraightClickDecorated}
                                line={bookLine.teamsLine.awaySpread}
                                {...rest}
                            />
                            <ButtonTeaser
                                onClick={onTeaserClickDecorated}
                                line={bookLine.teamsLine.awaySpread}
                                {...rest}
                            />
                        </React.Fragment>
                    )}
                    {showTotals && (
                        <React.Fragment>
                            <ButtonSpread
                                onClick={onStraightClickDecorated}
                                line={bookLine.teamsLine.over}
                                {...rest}
                            />
                            <ButtonTeaser
                                onClick={onTeaserClickDecorated}
                                line={bookLine.teamsLine.over}
                                {...rest}
                            />
                        </React.Fragment>
                    )}
                    <div className={classes.listItemText}>
                        <BookLogoLink book={bookLine.book} />
                    </div>
                    {showSpreads && (
                        <React.Fragment>
                            <ButtonSpread
                                onClick={onStraightClickDecorated}
                                line={bookLine.teamsLine.homeSpread}
                                {...rest}
                            />
                            <ButtonTeaser
                                onClick={onTeaserClickDecorated}
                                line={bookLine.teamsLine.homeSpread}
                                {...rest}
                            />
                        </React.Fragment>
                    )}
                    {showTotals && (
                        <React.Fragment>
                            <ButtonSpread
                                onClick={onStraightClickDecorated}
                                line={bookLine.teamsLine.under}
                                {...rest}
                            />
                            <ButtonTeaser
                                onClick={onTeaserClickDecorated}
                                line={bookLine.teamsLine.under}
                                {...rest}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </ListItem>
    );
});
