import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { PicksContent } from './PickDetails';
import Divider from '@material-ui/core/Divider';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from 'mdi-material-ui/DeleteOutline';
import SaveIcon from 'mdi-material-ui/CloudCheck';
import EditIcon from 'mdi-material-ui/CircleEditOutline';
import EnteredIcon from 'mdi-material-ui/MarkerCheck';
import Button from '@material-ui/core/Button';

import Snackbar from '@material-ui/core/Snackbar';
import green from '@material-ui/core/colors/green';
import { GET_WAGERS_BY_WEEK } from '../Query';
import { UPDATE_WAGER, DELETE_WAGER } from '../Mutation';
import DeleteConfirmation from '../Util/DeleteConfirmation';
import { WagerHeader } from '../Util/BookLogoLink';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//import Collapse from '@material-ui/core/Collapse';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 50,
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing.unit,
    },
    cardRoot: {
        //maxWidth: 325,
        width: 325,
        //center text in card
        textAlign: 'center',
        margin: 10,
    },
    cardActions: {
        display: 'flex',
        //justifyContent: 'flex-end',
        justifyContent: 'space-between',
        padding: 0,
    },
    editContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    cardContent: {
        padding: 8,
        paddingTop: 0,
    },
    menuSpacer: {
        marginRight: 16,
        width: 24,
    },
});

const DeleteWager = ({ id, children, variables }) => {
    const handleDelete = (e, deleteWager) => {
        e.preventDefault();
        deleteWager({ variables: { id: id } });
    };
    return (
        <Mutation
            mutation={DELETE_WAGER}
            update={(cache, { data: { deleteWager } }) => {
                //DEVNOTE the mutation returns the id, so deleteWager is the id
                const { wagersByWeek } = cache.readQuery({ query: GET_WAGERS_BY_WEEK, variables });
                let index = wagersByWeek.findIndex((w) => w.id === deleteWager);
                if (index > -1) {
                    let removedWager = wagersByWeek.splice(index, 1);
                    cache.writeQuery({
                        query: GET_WAGERS_BY_WEEK,
                        variables,
                        data: { wagersByWeek: wagersByWeek },
                    });
                }
            }}
        >
            {(deleteWager, { data }) => (
                <DeleteConfirmation onDelete={(e) => handleDelete(e, deleteWager)}>
                    {children}
                </DeleteConfirmation>
            )}
        </Mutation>
    );
};

//could make one mode edit where I can edit everythign and the other mode view where I just edit the w/l/t status

class EditWager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: this.props.wager.amount || '',
            openSnack: false,
            odds: this.props.wager.odds || '',
            entered: this.props.wager.entered,
            anchorEl: null,
            picks: this.props.wager.picks,
            editMode: this.props.wager.amount <= 0,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePickEdit = this.handlePickEdit.bind(this);
        // this.renderTextInput = this.renderTextInput.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }

    handlePickEdit(e, pick) {
        const val = e.target.value;
        this.setState({
            picks: this.state.picks.map((el) =>
                el === pick ? Object.assign({}, el, { adjValue: val }) : el
            ),
        });
    }

    handleSnackClose = (event, reason) => {
        this.setState({ openSnack: false });
    };
    submitForm(e, updateBet) {
        e.preventDefault();
        let { id } = this.props.wager;
        let updatedPicks = this.state.picks.map((p) => ({
            id: p.id,
            adjValue: Number(p.adjValue),
        }));
        let qParams = {
            variables: {
                updateWagerInput: {
                    id,
                    amount: this.state.amount,
                    odds: this.state.odds,
                    entered: this.state.entered,
                    //result:null,
                    picks: updatedPicks,
                },
            },
        };
        updateBet(qParams);
    }

    handleMenuOpen = (e) => {
        this.setState({ anchorEl: e.currentTarget });
    };
    handleMenuClose = (e) => {
        this.setState({ anchorEl: null });
    };
    handleCancelEdit = (e) => {
        this.setState({
            editMode: false,
            wager: this.props.wager,
            picks: this.props.wager.picks,
        });
        this.handleMenuClose();
    };

    toggleEditMode = (e) => {
        this.setState({ editMode: !this.state.editMode });
        this.handleMenuClose();
    };

    render() {
        let { classes, variables } = this.props;
        let { amount, odds, entered, anchorEl, picks, editMode } = this.state;
        let { id, book } = this.props.wager;

        return (
            <Mutation
                mutation={UPDATE_WAGER}
                key={id}
                update={(cache, { data: { updateWager } }) => {
                    this.setState({
                        openSnack: true,
                        editMode: false,
                        anchorEl: null,
                    });
                }}
            >
                {(updateBet, { loading, error }) => (
                    <div>
                        {loading && <p>Loading...</p>}
                        {error && <p>Error :( Please try again</p>}
                        <form onSubmit={(e) => this.submitForm(e, updateBet)}>
                            <Card className={classes.cardRoot}>
                                <WagerHeader book={book}>
                                    <IconButton
                                        aria-owns={anchorEl ? 'more-menu' : null}
                                        aria-haspopup="true"
                                        onClick={this.handleMenuOpen}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="more-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={this.handleMenuClose}
                                    >
                                        <MenuItem onClick={(e) => this.submitForm(e, updateBet)}>
                                            <ListItemIcon>
                                                <SaveIcon />
                                            </ListItemIcon>
                                            Save
                                        </MenuItem>
                                        <MenuItem onClick={this.toggleEditMode}>
                                            <ListItemIcon>
                                                <EditIcon />
                                            </ListItemIcon>
                                            Edit
                                        </MenuItem>
                                        <MenuItem>
                                            <DeleteWager id={id} variables={variables}>
                                                <ListItemIcon>
                                                    <DeleteIcon />
                                                </ListItemIcon>
                                                Delete
                                            </DeleteWager>
                                        </MenuItem>
                                    </Menu>
                                </WagerHeader>
                                <CardContent className={classes.cardContent}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            {/* <PickHeader></PickHeader> */}
                                            <PicksContent
                                                editMode={editMode}
                                                picks={picks}
                                                onChange={this.handlePickEdit}
                                            />
                                        </Grid>
                                        {!editMode && (
                                            <Grid item>
                                                ${amount}
                                                {entered && (
                                                    <EnteredIcon
                                                        color="secondary"
                                                        style={{
                                                            paddingTop: 2,
                                                            marginLeft: 8,
                                                            fontSize: 16,
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        )}
                                        {editMode && (
                                            <Grid item container direction="row" spacing={8}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        onFocus={(e) => e.target.select()}
                                                        required
                                                        id="amount"
                                                        label="Amt"
                                                        className={classes.textField}
                                                        name="amount"
                                                        value={amount}
                                                        onChange={this.handleInputChange}
                                                    />

                                                    <TextField
                                                        onFocus={(e) => e.target.select()}
                                                        required
                                                        id="odds"
                                                        label="Odds"
                                                        className={classes.textField}
                                                        name="odds"
                                                        value={odds}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={entered}
                                                                color="secondary"
                                                                name="entered"
                                                                onChange={this.handleInputChange}
                                                            />
                                                        }
                                                        label="Entered"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </CardContent>
                                <Divider light />
                                {editMode && (
                                    <CardActions
                                        className={classes.cardActions}
                                        disableActionSpacing
                                    >
                                        <Button onClick={this.handleCancelEdit}>Cancel</Button>
                                        <IconButton aria-label="Save" color="primary" type="submit">
                                            <SaveIcon />
                                        </IconButton>
                                    </CardActions>
                                )}
                            </Card>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                open={this.state.openSnack}
                                onClose={this.handleSnackClose}
                                autoHideDuration={1500}
                                ContentProps={{
                                    'aria-describedby': 'message-id',
                                }}
                                message={<span id="message-id">Wager Saved</span>}
                            />
                        </form>
                    </div>
                )}
            </Mutation>
        );
    }
}

export default withStyles(styles, { withTheme: true })(EditWager);
