import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GamesList from './GamesList';
import BetBuilder from '../Wagers/BetBuilder';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { getDefaultWeek } from '../Util/FootballWeeks';

const styles = (theme) => ({
    root: {
        width: '100%',
        maxWidth: 1200,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'row',
    },
});

class GamesMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            straights: [],
            teasers: [],
            selectedWeek: getDefaultWeek(),
            gameFilters: {
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
                monday: true,
                tuesday: true,
                wednesday: true,
                pastGames: false,
                betGames: false,
            },
            showSpreads: true,
            showTotals: false,
        };

        this.handleStraightClick = this.handleStraightClick.bind(this);
        this.handleTeaserClick = this.handleTeaserClick.bind(this);
        this.removeItems = this.removeItems.bind(this);
        this.toggleLockedStatus = this.toggleLockedStatus.bind(this);
        this.handleSelectedWeekChange = this.handleSelectedWeekChange.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.handleToggleSpreadsTotals = this.handleToggleSpreadsTotals.bind(this);
    }

    toggleFilter(name) {
        //el => (el === selection ? Object.assign({}, el, { locked:locked }) : el)
        this.setState({
            gameFilters: Object.assign({}, this.state.gameFilters, {
                [name]: !this.state.gameFilters[name],
            }),
        });
    }

    handleSelectedWeekChange(week) {
        this.setState({
            selectedWeek: week,
        });
    }

    handleTeaserClick(teasedLineObj) {
        // need to check locked status, paid and teaser amt and if we have a match then update
        let availableTeaser = this.state.teasers.find((t) => {
            return (
                !t.locked &&
                t.paid === teasedLineObj.paid &&
                Math.abs(t.teaserAmt) === Math.abs(teasedLineObj.teaserAmt)
            );
        });
        let teasers;
        if (availableTeaser) {
            availableTeaser.picks.push(...teasedLineObj.picks);
            teasers = [...this.state.teasers];
        } else {
            teasers = [...this.state.teasers, teasedLineObj];
        }

        this.setState({
            teasers,
        });
    }

    handleStraightClick(lineObj) {
        this.setState({
            straights: [...this.state.straights, lineObj],
        });
    }

    handleToggleSpreadsTotals(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    toggleLockedStatus(e, selection) {
        const target = e.target;
        const locked = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            teasers: this.state.teasers.map((el) =>
                el === selection ? Object.assign({}, el, { locked: locked }) : el
            ),
        });
    }

    removeItems(type, testFn) {
        let filterTeasers = type == 'all' || type == 'teaser';
        let filterStraights = type == 'all' || type == 'straight';
        let updatedState = {};
        if (filterTeasers) {
            let newTeasers = this.state.teasers.concat([]);
            _.remove(newTeasers, testFn);
            updatedState.teasers = newTeasers;
        }
        if (filterStraights) {
            let newStraights = this.state.straights.concat([]);
            _.remove(newStraights, testFn);
            updatedState.straights = newStraights;
        }

        this.setState(updatedState);
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container direction="column">
                <Grid item>
                    <BetBuilder
                        removeItems={this.removeItems}
                        toggleLockedStatus={this.toggleLockedStatus}
                        teasers={this.state.teasers}
                        straights={this.state.straights}
                        selectedWeek={this.state.selectedWeek}
                    />
                </Grid>
                <Grid item style={{ padding: 8 }}>
                    <GamesList
                        showSpreads={this.state.showSpreads}
                        showTotals={this.state.showTotals}
                        gameFilters={this.state.gameFilters}
                        selectedWeek={this.state.selectedWeek}
                        toggleFilter={this.toggleFilter}
                        onWeekChange={this.handleSelectedWeekChange}
                        onTeaserClick={this.handleTeaserClick}
                        onStraightClick={this.handleStraightClick}
                        toggleSpreadsTotals={this.handleToggleSpreadsTotals}
                        toggleOverrideCache={this.toggleOverrideCache}
                    />
                </Grid>
                {/* <BidForm statObj={this.state.statObj} /> */}
            </Grid>
        );
    }
}

GamesMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(GamesMenu);
