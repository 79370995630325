import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CoinIcon from 'mdi-material-ui/Coin';
import MedalIcon from 'mdi-material-ui/Medal';
import ScheduleIcon from 'mdi-material-ui/ClockOutline';
import WagerList from './WagerList';
import Record from './Record';
import WagerSchedule from './WagerSchedule';
import Grades from './Grades';
import { GET_WAGERS_BY_WEEK } from '../Query';
import _ from 'lodash';
import moment from 'moment';
import { Query } from 'react-apollo';
import QueryProgressIndicator from '../Util/QueryProgressIndicator';
import FootballWeeks, { NFL_SEASON, getDefaultWeek } from '../Util/FootballWeeks';
import WagerFilters from '../Filters/WagerFilters';
import { transformToPickGroups } from './Helpers';

const styles = (theme) => ({
    root: {},
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 }}>
            {props.children}
        </Typography>
    );
}

//slightly tricky b/c in one case we have a list of wagers to filter and the other we have a grouped list of picks;
const filterGroupedPicks = (groupedPicks, wagerFilters) => {
    // wagerFilters:{
    //   thursday:true,
    //   friday:false,
    //   saturday:false,
    //   sunday:true,
    //   monday:true,
    //   pastGames:true,
    //   deadTeasers:false,
    //   gradedGames:false
    // }
    let { pastGames, deadTeasers, gradedGames, ...dayFilters } = wagerFilters;
    //first delete any days that are not wanted
    let filtered = groupedPicks.filter((grp) => {
        let dayKey = grp.mDate.format('dddd').toLowerCase();
        return dayFilters[dayKey]; //i.e. ==true means we want that one
    });

    let hidePastGames = !pastGames;
    if (hidePastGames) {
        let current = moment();
        filtered = filtered.map((grp) => {
            //grp.events.forEach(e=> console.log(moment(e).isAfter(current)));
            //console.log(grp.events)
            return {
                picks: grp.picks.filter((p) => moment(p.date).isAfter(current)),
                //events:grp.events,
                mDate: grp.mDate,
            };
        });
    }

    let hideDeadTeasers = !deadTeasers;
    if (hideDeadTeasers) {
        filtered = filtered.map((grp) => {
            return {
                picks: grp.picks.filter(
                    (p) => (p.wagerType == 'T' && p.wagerResult != 'L') || p.wagerType == 'S'
                ),
                mDate: grp.mDate,
            };
        });
    }

    let hideGradedGames = !gradedGames;
    if (hideGradedGames) {
        filtered = filtered.map((grp) => {
            return {
                picks: grp.picks.filter((p) => p.result == null || p.result == 'U'),
                mDate: grp.mDate,
            };
        });
    }

    return filtered;
};

const filterWagers = (wagers, wagerFilters) => {
    let { pastGames, deadTeasers, gradedGames, ...dayFilters } = wagerFilters;
    let filtered = wagers.filter((w) => {
        return w.picks.some((p) => {
            let dayKey = moment(p.date).format('dddd').toLowerCase();
            return dayFilters[dayKey]; //i.e. ==true means we want that one
        });
    });

    let hidePastGames = !pastGames;
    if (hidePastGames) {
        let current = moment();
        //hide if all picks are in the past
        filtered = filtered.filter((w) => {
            return w.picks.some((p) => moment(p.date).isAfter(current));
        });
    }
    let hideDeadTeasers = !deadTeasers;
    if (hideDeadTeasers) {
        filtered = filtered.filter((w) => (w.type == 'T' && w.result != 'L') || w.type == 'S');
    }

    let hideGradedGames = !gradedGames;
    if (hideGradedGames) {
        filtered = filtered.filter((w) => w.result == null || w.result == 'U');
    }

    return filtered;
};

const getRecordForWeek = (wagers) => {
    return wagers.reduce(
        (recObj, w) => {
            return {
                total: recObj.total + 1,
                W: recObj.W + Number(w.result === 'W'),
                L: recObj.L + Number(w.result === 'L'),
                T: recObj.T + Number(w.result === 'T'),
                U: recObj.U + Number(w.result === null),
            };
        },
        { total: 0, W: 0, L: 0, T: 0, U: 0 }
    );
};

class WagerMenu extends React.Component {
    constructor(props) {
        super(props);
        let dayOfWeek = new Date().getDay();
        let selectedTab = dayOfWeek == 0 ? 1 : 0;
        this.state = {
            selectedTab,
            straights: [],
            teasers: [],
            selectedWeek: getDefaultWeek(),
            wagerFilters: {
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
                monday: true,
                pastGames: false,
                deadTeasers: true,
                gradedGames: false,
            },
        };

        this.handleSelectedWeekChange = this.handleSelectedWeekChange.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
    }

    handleChange = (event, selectedTab) => {
        this.setState({ selectedTab });
    };

    toggleFilter(name) {
        this.setState({
            wagerFilters: Object.assign({}, this.state.wagerFilters, {
                [name]: !this.state.wagerFilters[name],
            }),
        });
    }

    handleSelectedWeekChange(week) {
        this.setState({
            selectedWeek: week,
        });
    }

    render() {
        const { selectedTab, selectedWeek, wagerFilters } = this.state;
        const weekObj = NFL_SEASON[selectedWeek - 1];
        const year = weekObj.dates[0].year();
        const week = selectedWeek;
        const queryVariables = { year, week };
        return (
            <React.Fragment>
                <Paper square>
                    <Tabs
                        value={selectedTab}
                        onChange={this.handleChange}
                        fullWidth
                        indicatorColor="secondary"
                        textColor="secondary"
                    >
                        <Tab icon={<CoinIcon />} label="Manage Wagers" />
                        <Tab icon={<ScheduleIcon />} label="Schedule" />
                        <Tab icon={<MedalIcon />} label="Grades" />
                    </Tabs>
                </Paper>
                <Query query={GET_WAGERS_BY_WEEK} variables={queryVariables}>
                    {({ loading, error, data }) => {
                        if (loading) return <QueryProgressIndicator />;
                        if (error) return <p>Error :(</p>;
                        const record = getRecordForWeek(data.wagersByWeek);
                        const filteredWagers = filterWagers(data.wagersByWeek, wagerFilters);
                        const pickGroups = transformToPickGroups(data.wagersByWeek);
                        const filteredPickGroups = filterGroupedPicks(pickGroups, wagerFilters);
                        const daysAvailableForFilter = pickGroups.map((g) =>
                            g.mDate.format('dddd').toLowerCase()
                        );
                        return (
                            <React.Fragment>
                                <Paper square>
                                    <FootballWeeks
                                        selectedWeek={selectedWeek}
                                        onWeekChange={this.handleSelectedWeekChange}
                                    />
                                    <Record record={record} />
                                    <WagerFilters
                                        wagerFilters={wagerFilters}
                                        toggleFilter={this.toggleFilter}
                                        selectedWeek={selectedWeek}
                                        availableDates={daysAvailableForFilter}
                                    />
                                </Paper>
                                {selectedTab === 0 && (
                                    <TabContainer>
                                        <WagerList
                                            wagers={filteredWagers}
                                            variables={queryVariables}
                                        />
                                    </TabContainer>
                                )}
                                {selectedTab === 1 && (
                                    <TabContainer>
                                        <WagerSchedule pickGroups={filteredPickGroups} />
                                    </TabContainer>
                                )}
                                {selectedTab === 2 && (
                                    <TabContainer>
                                        <Grades
                                            pickGroups={filteredPickGroups}
                                            variables={queryVariables}
                                        />
                                    </TabContainer>
                                )}
                            </React.Fragment>
                        );
                    }}
                </Query>
            </React.Fragment>
        );
    }
}
export default withStyles(styles, { withTheme: true })(WagerMenu);
