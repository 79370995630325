import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import EditWager from './EditWager';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 100,
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing.unit,
    },
});

const Wagers = ({ classes, wagers, variables }) => (
    <Grid item container direction="row" spacing={8} justify="flex-start" alignItems="flex-start">
        <Grid item style={{ flexBasis: '90%', marginBottom: 0, marginTop: 10 }}>
            <Typography color="secondary" variant="subheading">
                Teasers
            </Typography>
        </Grid>
        {wagers
            .filter((w) => w.type == 'T')
            .map((wager) => (
                <Grid item key={wager.id}>
                    <EditWager wager={wager} variables={variables} />
                </Grid>
            ))}
        <Grid item style={{ flexBasis: '90%', marginBottom: 0, marginTop: 10 }}>
            <Typography color="secondary" variant="subheading">
                Straight
            </Typography>
        </Grid>
        {wagers
            .filter((w) => w.type == 'S')
            .map((wager) => (
                <Grid item key={wager.id}>
                    <EditWager wager={wager} variables={variables} />
                </Grid>
            ))}
    </Grid>
);

export default withStyles(styles, { withTheme: true })(Wagers);
