import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { TEAM_INJURIES } from '../Query';
import QueryProgressIndicator from '../Util/QueryProgressIndicator';
import { getDefaultYear, getDefaultWeek } from '../Util/FootballWeeks';

// todo could add badge for which book has active teaser and how many
const styles = (theme) => ({
    divider: {
        //background:'#fafafa'
        background: theme.palette.primary.dark,
        height: 3,
        opacity: 0.5,
        margin: 4,
    },
    table: {
        fontSize: '.75rem',
        maxWidth: 800,
    },
    tableCell: {
        fontSize: '.8rem',
    },
});

export const Injuries = withStyles(styles, { withTheme: true })(({ classes, teamId }) => {
    const week = getDefaultWeek();
    const queryVariables = {
        teamId,
        year: getDefaultYear(),
        week,
    };
    //console.log(queryVariables);
    return (
        <Query query={TEAM_INJURIES} variables={queryVariables}>
            {({ loading, error, data }) => {
                if (loading) return <QueryProgressIndicator />;
                if (error) return <p>Error :(</p>;
                const { teamInjuries = [] } = data;
                const teamName = teamInjuries?.length ? teamInjuries[0].teamName : null;
                const transformedInjuries = transformInjuryData(teamInjuries, week);
                // console.log(linesByBook); undefined
                return (
                    <React.Fragment>
                        <div>{teamName} Injuries</div>
                        <div className={classes.divider}></div>
                        <Table className={classes.table} size="small" padding="checkbox">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Player</TableCell>
                                    <TableCell>Pos</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Upddate</TableCell>
                                    <TableCell>Snps</TableCell>
                                    <TableCell>Snps ly</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transformedInjuries.map((inj, idx) => {
                                    const {
                                        playerName,
                                        type,
                                        status,
                                        position,
                                        updated,
                                        snps,
                                        snps_ly,
                                    } = inj;
                                    // const snps_ly = osnps_ly > dsnps_ly ? osnps_ly : dsnps_ly;
                                    // const snps = osnps > dsnps ? osnps : dsnps;
                                    const showDivider = false;
                                    let divider = showDivider ? (
                                        <TableRow className={classes.divider}>
                                            <TableCell colSpan={5}></TableCell>
                                        </TableRow>
                                    ) : null;
                                    return (
                                        <React.Fragment key={idx}>
                                            {divider}
                                            <TableRow>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    className={classes.tableCell}
                                                >
                                                    {playerName}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {position}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {type}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {status}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {updated}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {snps}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {snps_ly}
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </React.Fragment>
                );
            }}
        </Query>
    );
});

function transformInjuryData(teamInjuries, week) {
    const mappedInj = teamInjuries.map((inj) => {
        const { osnps, dsnps, osnps_ly, dsnps_ly } = inj;
        const snps_ly = osnps_ly > dsnps_ly ? osnps_ly : dsnps_ly;
        const snps = osnps > dsnps ? osnps : dsnps;
        return { ...inj, snps, snps_ly };
    });
    const sortCol = week > 4 ? 'snps' : 'snps_ly';
    mappedInj.sort((a, b) => b[sortCol] - a[sortCol]);
    return mappedInj;
}
