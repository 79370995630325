import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import moment from 'moment';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardContent';
import LineValue from '../Util/LineValue';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { getTeaserResultColor } from './Helpers';

const betCardStyles = (theme) => ({
    //   root:{1
    //     maxWidth: 255,
    //     minWidth: 250,
    //     height:148,
    //     width:250,
    //     //center card in page
    //     margin: '0 auto',
    //     //center text in card
    //     textAlign: 'center',
    //     padding:4,
    //     borderRadius: "4px 24px"
    //   },
    header: {
        padding: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    pickContent: {
        display: 'flex',
        //alignItems: 'center',
        justifyContent: 'space-evenly',
        //paddingLeft: theme.spacing.unit,
        //paddingBottom: theme.spacing.unit,
        flexDirection: 'row',
        padding: 4,
    },
    columnA: {
        flexBasis: '60%',
    },
    columnB: {
        flexBasis: '40%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: 8,
    },
    teams: {
        display: 'flex',
        alignItems: 'flex-start',
        //justifyContent:'space-evenly',
        flexDirection: 'column',
        padding: 2,
    },
    teamRow: {
        display: 'flex',
    },
    teamLogo: {
        height: 20,
        marginRight: 10,
    },
    dateLine: {
        marginBottom: 6,
    },
    cardContent: {
        padding: 0,
        '&:last-child': {
            paddingBottom: 4,
        },
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 50,
    },
    divider: {
        marginLeft: '45%',
        width: '30%',
    },
});

export const PickContent = withStyles(betCardStyles, { withTheme: true })(
    ({ hideDate, pick, classes, editMode, onChange, picks, pickIdx }) => {
        const { homeTeam, awayTeam, subType, type, date, selectedPartId } = pick;
        const mDate = moment(date);
        let formattedSelectionVal = '';
        if (type == 'total') {
            formattedSelectionVal = subType;
        } else if (homeTeam.partid == selectedPartId) {
            formattedSelectionVal = homeTeam.name;
        } else if (awayTeam.partid == selectedPartId) {
            formattedSelectionVal = awayTeam.name;
        } else {
            formattedSelectionVal = 'ERROR';
        }
        let daysOut = mDate.diff(moment(), 'days');
        let useCloseInFormat = daysOut < 7 && daysOut > -1;
        let formmattedDate = useCloseInFormat
            ? mDate.format('ddd,  h:mm A')
            : mDate.format('M/D  h:mm A');
        const homeImage = `images/teams/${awayTeam.abbrev}.svg`;
        const awayImage = `images/teams/${homeTeam.abbrev}.svg`;
        const color = getTeaserResultColor(pickIdx, picks);
        console.log(color);
        //const localDateTime ="test";
        return (
            <div className={classes.pickContent} style={{ backgroundColor: color }}>
                <div className={classNames(classes.columnA, classes.teams)}>
                    {!hideDate && (
                        <Typography variant="caption" className={classNames(classes.dateLine)}>
                            {formmattedDate}
                        </Typography>
                    )}
                    <Typography className={classes.teamRow} color="textSecondary">
                        <img src={homeImage} className={classes.teamLogo} /> {awayTeam.name}
                    </Typography>
                    <Typography className={classes.teamRow} color="textSecondary">
                        <img src={awayImage} className={classes.teamLogo} /> {homeTeam.name}
                    </Typography>
                </div>
                <div className={classNames(classes.columnB, classes.helper)}>
                    <Typography variant="button">{formattedSelectionVal}</Typography>
                    {editMode ? (
                        <TextField
                            onFocus={(e) => e.target.select()}
                            id="adjValue"
                            label={pick.type}
                            className={classes.textField}
                            name="adjValue"
                            value={pick.adjValue}
                            onChange={(e) => onChange(e, pick)}
                        />
                    ) : (
                        <LineValue value={pick.adjValue} {...pick} />
                    )}
                </div>
            </div>
        );
    }
);

export const PicksContent = withStyles(betCardStyles, { withTheme: true })(
    ({ picks, classes, ...rest }) => {
        return (
            <CardContent className={classes.cardContent}>
                {picks.map((pick, idx) => (
                    <React.Fragment key={idx}>
                        <PickContent pick={pick} pickIdx={idx} picks={picks} {...rest} />
                        {idx < picks.length - 1 && <Divider className={classes.divider} />}
                    </React.Fragment>
                ))}
            </CardContent>
        );
    }
);

export const PickHeader = withStyles(betCardStyles, { withTheme: true })(
    ({ date, classes, children }) => {
        const mDate = moment(date);
        return (
            <CardHeader className={classes.header}>
                <div></div>
                {/* <Typography variant="caption">
                {mDate.format("M/D  h:m A")}
            </Typography> */}
                {children}
            </CardHeader>
        );
    }
);
