import React from 'react';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AtIcon from 'mdi-material-ui/At';
import Divider from '@material-ui/core/Divider';
import { grey } from '@material-ui/core/colors';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import DateFormat from '../Util/DateFormat';
import Team from './Team';
import { Weather } from './Weather';
import { Injuries } from './Injuries';
import { Gamelog } from './Gamelog';
import { GET_MATCHUP, GET_TRACKING_BY_TYPE } from '../Query';
import QueryProgressIndicator from '../Util/QueryProgressIndicator';

// todo could add badge for which book has active teaser and how many
const styles = (theme) => ({
    root: {
        //center card in page
        //margin: '0 auto',
        marginLeft: 8,
        marginRight: 8,
        // maxWidth: 1400,
        //center text in card
        textAlign: 'center',
        borderRadius: 20,
    },
    teamsContent: {
        display: 'flex',
        alignItems: 'center',
        padding: 8,
        textWrap: 'nowrap',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingTop: 0,
    },
    cardActions: {
        padding: '4px 4px',
    },
    atIcon: {
        fontSize: 20,
    },
    teamWrapper: {
        flex: 45,
    },
    cardHeader: {
        color: theme.palette.getContrastText(grey[200]),
        backgroundColor: grey[200],
        padding: 2,
    },
});

// todo this could be a generic component now looks like
export const TrackingLastUpdate = withStyles(styles, { withTheme: true })(({ classes, type }) => {
    const queryVariables = {
        type,
    };
    return (
        <Query query={GET_TRACKING_BY_TYPE} variables={queryVariables}>
            {({ loading, error, data }) => {
                if (loading) return <QueryProgressIndicator />;
                if (error) return <p>Error :(</p>;
                const { trackingDetails } = data;
                if (trackingDetails?.length) {
                    const trackingRec = trackingDetails[0];
                    return (
                        <div>
                            * {type} updated <DateFormat date={trackingRec.lastUpdate} />
                        </div>
                    );
                }
                return <div>{type} tracking data not found</div>;
            }}
        </Query>
    );
});

export const Matchup = withStyles(styles, { withTheme: true })(({ classes, eid }) => {
    const queryVariables = {
        eid,
    };
    return (
        <Query query={GET_MATCHUP} variables={queryVariables}>
            {({ loading, error, data }) => {
                if (loading) return <QueryProgressIndicator />;
                if (error) return <p>Error :(</p>;
                const { getEvent } = data;
                const { date, awayTeam, homeTeam, weather, linesByBook } = getEvent;
                // console.log(linesByBook); undefined
                console.log(awayTeam, homeTeam);
                return (
                    <Paper className={classes.root}>
                        <Grid container direction="column">
                            <Grid className={classes.cardHeader} item>
                                <Typography color="primary" style={{ fontSize: 14 }}>
                                    <DateFormat date={date} />
                                </Typography>
                            </Grid>
                            <Grid item className={classes.teamsContent}>
                                <div className={classes.teamWrapper}>
                                    <Team team={awayTeam} />
                                </div>
                                <div style={{ flex: 10 }}>
                                    <AtIcon className={classes.atIcon} />
                                </div>
                                <div className={classes.teamWrapper}>
                                    <Team team={homeTeam} />
                                </div>
                            </Grid>
                            <Grid item style={{ padding: 4 }}>
                                <Weather weather={weather} />
                            </Grid>
                            <Grid item style={{ padding: 4 }}>
                                <Divider />
                            </Grid>
                            <Grid item container direction="row" justify="space-between">
                                <Grid item>
                                    <Injuries teamId={awayTeam.teamId} />
                                </Grid>
                                <Grid item>
                                    <Injuries teamId={homeTeam.teamId} />
                                </Grid>
                            </Grid>
                            <Grid item style={{ padding: 4 }}>
                                <TrackingLastUpdate type="injuries" />
                            </Grid>
                            <Grid item container direction="row" justify="space-between">
                                <Grid item>
                                    <Gamelog abbrev={awayTeam.abbrev} />
                                </Grid>
                                <Grid item>
                                    <Gamelog abbrev={homeTeam.abbrev} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                );
            }}
        </Query>
    );
});
