import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from 'mdi-material-ui/DeleteOutline';
import LockOpenIcon from 'mdi-material-ui/LockOpenOutline';
import LockIcon from 'mdi-material-ui/LockOutline';
import TeaserIcon from 'mdi-material-ui/FormatText';
import { WagerHeader } from '../Util/BookLogoLink';
import { PicksContent } from './PickDetails';
import DeleteConfirmation from '../Util/DeleteConfirmation';
import { ADD_WAGER_MUTATION } from '../Mutation';
import { GET_WAGERS_BY_WEEK } from '../Query';
import { ApolloConsumer } from 'react-apollo';
import _ from 'lodash';
import { filter } from 'graphql-anywhere';
import { WAGER_INPUT } from '../Fragment/Wager';

import { NFL_SEASON } from '../Util/FootballWeeks';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
        flexWrap: 'wrap',
        overflow: 'auto',
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

const betCardStyles = (theme) => ({
    root: {
        maxWidth: 265,
        minWidth: 250,
        minHeight: 148,
        width: 262,
        //center card in page
        margin: '0 auto',
        //center text in card
        textAlign: 'center',
        padding: 4,
        borderRadius: '4px 24px',
    },
    actions: {
        display: 'flex',
        //justifyContent: 'flex-end',
        justifyContent: 'space-between',
        padding: 0,
    },
    teaserLabel: {
        display: 'flex',
        alignItems: 'center',
        //justifyContent: 'space-between',
    },
    teaserIcon: {
        fontSize: 18,
    },
});

/*
header -
indication of count of teasers by book, maybe with chip, or just count icon?
indication of staight bets by book
sort by book
*/

// TODO: Betbuilder should probably be a side menu instead of a top expansion panel
const BetCard = withStyles(betCardStyles, { withTheme: true })(
    class extends Component {
        constructor(props) {
            super(props);
            // this.state = {
            //     locked:false,
            // };
        }
        handleInputChange(event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            this.setState({
                [name]: value,
            });
        }
        render() {
            let { selection, type, removeItems, classes, toggleLockedStatus } = this.props;
            const removeSelf = () => removeItems(type, (s) => s === selection);
            return (
                <Card className={classes.root}>
                    <WagerHeader type={selection.type} book={selection.book}>
                        {selection.type === 'T' && (
                            <div className={classes.teaserLabel}>
                                <TeaserIcon className={classes.teaserIcon} />{' '}
                                <Typography variant="caption">{selection.teaserAmt}</Typography>
                            </div>
                        )}
                    </WagerHeader>
                    <PicksContent picks={selection.picks} />
                    <Divider light />
                    <CardActions className={classes.actions} disableActionSpacing>
                        <IconButton aria-label="Delete" onClick={removeSelf}>
                            <DeleteIcon />
                        </IconButton>
                        {toggleLockedStatus && (
                            <Checkbox
                                icon={<LockOpenIcon />}
                                checkedIcon={<LockIcon />}
                                onChange={(e) => toggleLockedStatus(e, selection)}
                                name="locked"
                                color="secondary"
                                checked={selection.locked}
                            />
                        )}
                    </CardActions>
                </Card>
            );
        }
    }
);

const updateGetWagers =
    (cacheUpdateVariables) =>
    (cache, { data: { addWager } }) => {
        try {
            const { wagersByWeek } = cache.readQuery({
                query: GET_WAGERS_BY_WEEK,
                variables: cacheUpdateVariables,
            });
            cache.writeQuery({
                query: GET_WAGERS_BY_WEEK,
                variables: cacheUpdateVariables,
                data: { wagersByWeek: [addWager].concat(wagersByWeek) },
            });
        } catch (error) {
            //skip... ugg
            //note for now, need to walk around this if get_wagers hasn't been called
            //https://github.com/apollographql/apollo-client/issues/1542
        }
    };

const saveWager = async (client, selection, cacheUpdateVariables) => {
    let { paid, odds, type, picks } = selection;
    let wager = {
        paid,
        odds,
        type,
        picks,
    };
    let wagerInput = filter(WAGER_INPUT, wager);

    let variables = {
        wagerInput,
    };

    let mutationParams = {
        mutation: ADD_WAGER_MUTATION,
        update: updateGetWagers(cacheUpdateVariables),
        variables,
    };

    let results = await client.mutate(mutationParams);
    let id = results.data.addWager.id;
    selection.wagerId = id;
    return results;

    //console.log(pick)
};

const SaveWagers = ({ teasers, straights, removeItems, cacheUpdateVariables }) => {
    const saveWagers = async (client) => {
        let allWagers = [...straights, ...teasers];
        return Promise.all(
            allWagers.map((selection) => saveWager(client, selection, cacheUpdateVariables))
        )
            .then((allResults) => {
                removeItems('all', (s) => s.wagerId > 0);
            })
            .catch((err) => console.error(err));
    };

    return (
        <ApolloConsumer>
            {(client) => (
                <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                        saveWagers(client, teasers, straights);
                    }}
                >
                    Save
                </Button>
            )}
        </ApolloConsumer>
    );
};

const BetBuilder = ({
    straights,
    teasers,
    removeItems,
    toggleLockedStatus,
    classes,
    selectedWeek,
}) => {
    // todo: this is used over and over, probably should just
    // send this object aroudn instead of selected week or at least make a function
    const weekObj = NFL_SEASON[selectedWeek - 1];
    const year = weekObj.dates[0].year();
    const week = selectedWeek;
    const cacheUpdateVariables = { year, week };
    return (
        <div className={classes.root}>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div className={classes.column}>
                        <Typography className={classes.heading}>BetBuilder</Typography>
                    </div>
                    <div className={classes.column}></div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                    <Grid container>
                        {teasers.length > 0 && (
                            <Grid item style={{ flexBasis: '90%' }}>
                                <Typography variant="subheading">Teasers</Typography>
                                <Divider light />
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        spacing={8}
                        justify="flex-start"
                        alignItems="center"
                    >
                        {teasers.map((teaser, idx) => (
                            <Grid item key={idx}>
                                <BetCard
                                    type="teaser"
                                    selection={teaser}
                                    removeItems={removeItems}
                                    toggleLockedStatus={toggleLockedStatus}
                                ></BetCard>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container>
                        {straights.length > 0 && (
                            <Grid item style={{ flexBasis: '90%' }}>
                                <Typography variant="subheading">Straigt Bets</Typography>
                                <Divider light />
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        spacing={8}
                        justify="flex-start"
                        alignItems="center"
                    >
                        {straights.map((straight, idx) => (
                            <Grid item key={idx}>
                                <BetCard
                                    type="straight"
                                    selection={straight}
                                    removeItems={removeItems}
                                ></BetCard>
                            </Grid>
                        ))}
                    </Grid>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <DeleteConfirmation
                        confirmMsg="Clear all bets?"
                        onDelete={() => removeItems('all', () => true)}
                    >
                        Clear
                    </DeleteConfirmation>
                    <SaveWagers
                        removeItems={removeItems}
                        teasers={teasers}
                        straights={straights}
                        cacheUpdateVariables={cacheUpdateVariables}
                    />
                </ExpansionPanelActions>
            </ExpansionPanel>
        </div>
    );
};

BetBuilder.propTypes = {
    classes: PropTypes.object.isRequired,
};

// const WithApolloClient = () => (
//   <ApolloConsumer>
//     {client => "We have access to the client!" /* do stuff here */}
//   </ApolloConsumer>
// );

export default withStyles(styles, { withTheme: true })(BetBuilder);

// class DelayedQuery extends Component {
//   state = { dog: null };

//   onDogFetched = dog => this.setState(() => ({ dog }));

//   render() {
//     return (
//       <ApolloConsumer>
//         {client => (
//           <div>
//             {this.state.dog && <img src={this.state.dog.displayImage} />}
//             <button
//               onClick={async () => {
//                 const { data } = await client.query({
//                   query: GET_DOG_PHOTO,
//                   variables: { breed: "bulldog" }
//                 });
//                 this.onDogFetched(data.dog);
//               }}
//             >
//               Click me!
//             </button>
//           </div>
//         )}
//       </ApolloConsumer>
//     );
//   }
// }
