import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TeaserIcon from 'mdi-material-ui/FormatText';
import _ from 'lodash';
import { PickContent } from './PickDetails';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import { getResultColor } from './Helpers';
import EditGrade from './EditGrade';

const styles = (theme) => ({
    root: {
        width: '100%',
        //maxWidth: 460,
        backgroundColor: theme.palette.background.paper,
        // display: 'flex',
        // flexWrap: 'wrap',
        // justifyContent: 'space-around',
        // alignItems:'center',
        // overflow: 'hidden',
        //backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        maxWidth: 600,
        //height: 450,
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    gridListTile: {
        // borderColor: 'rgba(0, 0, 0, 0.25)',
        // borderBottom:1,
        // borderStyle:'solid'
        //backgroundColor: 'rgba(0, 0, 0, 0.01)',
        backgroundColor: theme.palette.background.paper,
        margin: 8,
        marginTop: 0,
        //padding:4
    },
});

const pickItemStyles = (theme) => ({
    teaserLabel: {
        display: 'flex',
        alignItems: 'center',
        //justifyContent: 'space-between',
    },
    teaserIcon: {
        fontSize: 18,
    },
    // dateLine:{

    // },
    // headerRoot:{
    //     padding:12,
    //     display:'flex',
    //     alignItems: 'center',
    //     justifyContent: 'space-between',
    //     flexDirection:"row",
    // },
    listContent: {
        maxWidth: 300,
    },
});

const PickGradeItem = withStyles(pickItemStyles, { withTheme: true })(({ classes, pick }) => {
    let { wagerType, wagerResult } = pick;
    let rowColor = getResultColor(wagerResult);

    return (
        <ListItem divider style={{ backgroundColor: rowColor }}>
            {/* <ListItemIcon>
            </ListItemIcon> */}
            {wagerType === 'T' && (
                <div className={classes.teaserLabel}>
                    <TeaserIcon className={classes.teaserIcon} /> {pick.wagerId}
                </div>
            )}
            <ListItemText className={classes.listContent}>
                <PickContent hideDate={false} pick={pick} />
            </ListItemText>
            {/* <ListItemText>
                <PickContent hideDate={true} pick={pick} />
            </ListItemText> */}
            <ListItemSecondaryAction>
                <EditGrade pick={pick} />
            </ListItemSecondaryAction>
        </ListItem>
    );
});

const PicksForDay = withStyles(styles, { withTheme: true })(({ date, mDate, picks, classes }) => {
    let formattedDate = mDate.format('dddd (M/D)');
    return (
        <div className={classes.root}>
            <List
                subheader={
                    <ListSubheader disableSticky color="primary">
                        {formattedDate}
                    </ListSubheader>
                }
            >
                {picks.map((pick) => (
                    <PickGradeItem key={pick.id} pick={pick} />
                ))}
            </List>
        </div>
    );
});

const PickScheduleList = (props) => {
    const { pickGroups } = props;
    return (
        <React.Fragment>
            {pickGroups.map(({ picks, mDate }, idx) => (
                <PicksForDay key={idx} mDate={mDate} picks={picks} {...props} />
            ))}
        </React.Fragment>
    );
};

export default withStyles(styles, { withTheme: true })(PickScheduleList);
