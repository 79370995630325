import React, { Component } from 'react';
import injectTapEventPlugin from 'react-tap-event-plugin';
import {
    //  BrowserRouter as Router,
    Route,
    Link,
    //Redirect,
    withRouter,
} from 'react-router-dom';

import { AdminRoute, PropsRoute, LoggedOutRoute, PrivateRoute } from './Util/RouteUtil';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

//from orig
import HomePage from './HomePage';
import LogoutFunction from '../containers/LogoutFunction';
import Auth from '../modules/Auth';
import ComingSoon from './ComingSoon';
import BetsPage from './Bets';
import WagerMenu from './Wagers/WagerMenu';
import LoginPage from '../containers/LoginPage';
import SignUpPage from '../containers/SignUpPage';
import DashboardPage from '../containers/DashboardPage';
import { MatchupSelectionPage } from './MatchupSelectionPage';
//import RenoMenu from './Reno/RenoMenu'
import Games from './Games/GamesMenu';

//These work, removing for the time being
// import Books  from './Books'
// import SimpleTable from './SimpleTable';
// import Lines from './Lines'
// import DPTeams from './DPTeams'
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PersonPinIcon from '@material-ui/icons/PersonPin';

import CoinIcon from 'mdi-material-ui/Coin';
import FootballIcon from 'mdi-material-ui/Football';
//really like this icon
//import ReportsIcon from 'mdi-material-ui/ChartGantt';
import DashboardIcon from 'mdi-material-ui/Creation';

const styles = {
    // root: {
    //   flexGrow: 1,
    //   marginBottom:20
    // },
    flex: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    tabArea: {
        maxWidth: 400,
    },
    tabsRoot: {
        maxWidth: 575,
    },
    tabRoot: {
        minWidth: 50,
        /*maxWidth:95*/
    },
};

// remove tap delay, essential for MaterialUI to work properly
injectTapEventPlugin();

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            //selectedTab:0
        };
        //this.userIsAdmin = this.userIsAdmin.bind(this);
        this.tabsValue = this.tabsValue.bind(this);
    }

    handleChange = (event, value) => {
        //this.setState({ selectedTab });
        this.props.history.push(value);
    };

    componentDidMount() {
        // check if user is logged in on refresh
        let user = Auth.getUser();
        this.toggleAuthenticateStatus(user);
    }

    toggleAuthenticateStatus(user) {
        // check authenticated status and toggle state based on that
        this.setState({
            authenticated: Auth.isUserAuthenticated(),
            isAdmin: Auth.isUserAdmin(user),
        });
    }
    tabsValue() {
        //the other tab-related warning is related to mounting the non-logged in version first
        let path = this.props.history.location.pathname;
        const possibleTabsVals = [
            '/games',
            '/grades',
            '/wagers',
            '/coming_soon',
            '/login',
            '/signup',
            '/logout',
        ];
        return path && possibleTabsVals.includes(path) ? path : false;
    }
    render() {
        const { classes, location } = this.props;
        const searchParams = new URLSearchParams(location.search);
        const eid = searchParams.get('eid');
        const tabValue = this.tabsValue();
        const unAuthenticatedTabValue = ['/signup', '/login'].includes(tabValue) ? tabValue : false;
        return (
            <div>
                {/* <div className={classes.root}> */}
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="Menu"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="title" color="secondary" className={classes.flex}>
                            <Link color="secondary" to="/">
                                Betio{' '}
                            </Link>
                        </Typography>
                        {this.state.authenticated && this.state.isAdmin && (
                            <Tabs
                                value={tabValue}
                                onChange={this.handleChange}
                                classes={{ root: classes.tabsRoot }}
                            >
                                <Tab
                                    classes={{ root: classes.tabRoot }}
                                    value="/games"
                                    label="Games"
                                    icon={<FootballIcon />}
                                    component={Link}
                                    to="/games"
                                />
                                <Tab
                                    value="/wagers"
                                    classes={{ root: classes.tabRoot }}
                                    label="Bets"
                                    icon={<CoinIcon />}
                                    component={Link}
                                    to="/wagers"
                                />
                                <Tab
                                    value="/grades"
                                    classes={{ root: classes.tabRoot }}
                                    label="Dashboard"
                                    icon={<DashboardIcon />}
                                    component={Link}
                                    to="/dashboard"
                                />
                                <Tab
                                    value="/logout"
                                    classes={{ root: classes.tabRoot }}
                                    label="Log out"
                                    icon={<PersonPinIcon />}
                                    component={Link}
                                    to="/logout"
                                />
                            </Tabs>
                        )}

                        {this.state.authenticated && !this.state.isAdmin && (
                            <Tabs value={tabValue}>
                                <Tab
                                    value="/coming_soon"
                                    label="Main"
                                    component={Link}
                                    to="/coming_soon"
                                />
                                <Tab
                                    value="/logout"
                                    label="Log out"
                                    component={Link}
                                    to="/logout"
                                />
                            </Tabs>
                        )}

                        {!this.state.authenticated && (
                            <Tabs value={unAuthenticatedTabValue}>
                                <Tab value="/login" label="Login" component={Link} to="/login" />
                                <Tab
                                    value="/signup"
                                    label="Sign up"
                                    component={Link}
                                    to="/signup"
                                />
                            </Tabs>
                        )}
                    </Toolbar>
                </AppBar>
                <PropsRoute
                    exact
                    path="/"
                    component={HomePage}
                    toggleAuthenticateStatus={(u) => this.toggleAuthenticateStatus(u)}
                />
                <React.Fragment>
                    <AdminRoute exact path="/games" component={Games} />
                    <AdminRoute exact path="/games/matchup" component={MatchupSelectionPage} />
                    <AdminRoute path="/dashboard" component={DashboardPage} />
                    <AdminRoute exact path="/bets" component={BetsPage} />
                    <AdminRoute exact path="/wagers" component={WagerMenu} />
                </React.Fragment>
                <PrivateRoute exact path="/coming_soon" component={ComingSoon} />
                <LoggedOutRoute
                    path="/login"
                    component={LoginPage}
                    toggleAuthenticateStatus={(u) => this.toggleAuthenticateStatus(u)}
                />
                <LoggedOutRoute path="/signup" component={SignUpPage} />
                <Route path="/logout" component={LogoutFunction} />
            </div>
        );
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(Main));
