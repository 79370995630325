import gql from 'graphql-tag';

export const TEAM_INFO = gql`
    fragment teamInfo on SBTeam {
        partid
        teamId
        city
        isHome
        name
        abbrev
    }
`;

export const TEAM_INPUT = gql`
    fragment teamInput on WagerTeamInput {
        partid
        teamId
        city
        isHome
        name
        abbrev
    }
`;
