import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { HtmlUploaderBase } from './HtmlUpdaterBase';

const uploaderStyles = (theme) => ({
    cardHeader: {
        color: theme.palette.getContrastText(blue[400]),
        backgroundColor: blue[400],
        padding: 6,
    },
    textarea: {
        width: 200,
        height: 50,
        padding: 8,
        boxSizing: 'border-box',
        border: '2px solid #ccc',
        borderRadius: 4,
        backgroundColor: '#f8f8f8',
        fontSize: 16,
    },
});

export const DSIUpdater = withStyles(uploaderStyles, { withTheme: true })((props) => {
    return <HtmlUploaderBase {...props} type="betdsi" typeLabel="BetDSI" />;
});
