// basically combine all into one, then call a fn to let it upodate all the others
// do it like the heritage one so it is simple
// step 1 - call update all
// step 2 - return from update all is dateUpdated for each type
// step 3 - call props.updateExternalDatea(allDates) which will be passed in and then the external dates passed into
// each specific updater
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardContent';
import FootballWeeks, { getDefaultWeek, NFL_SEASON } from '../Util/FootballWeeks';
import { orange } from '@material-ui/core/colors';
import Auth from '../../modules/Auth';
const uploaderStyles = (theme) => ({
    cardHeader: {
        color: theme.palette.getContrastText(orange[400]),
        backgroundColor: orange[400],
        padding: 6,
    },
    textarea: {
        width: 200,
        height: 50,
        padding: 8,
        boxSizing: 'border-box',
        border: '2px solid #ccc',
        borderRadius: 4,
        backgroundColor: '#f8f8f8',
        fontSize: 16,
        resize: 'none',
    },
});

class AllSourcesUpdaterBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedWeek: getDefaultWeek(),
            data: null,
        };
        this.handleSelectedWeekChange = this.handleSelectedWeekChange.bind(this);
    }

    handleSelectedWeekChange(week) {
        this.setState({
            selectedWeek: week,
        });
    }

    getVariables() {
        const { type } = this.props;
        const { selectedWeek } = this.state;
        let weekObj = NFL_SEASON[selectedWeek - 1];
        let year = weekObj.dates[0].year();
        return {
            type,
            week: selectedWeek,
            year,
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        try {
            const data = JSON.parse(value);
            this.setState({
                [name]: data,
            });
        } catch (error) {}
    }

    async updateAll(event) {
        const { data } = this.state;
        const { afterUpdate } = this.props;
        const variables = this.getVariables();
        const body = { ...variables };
        if (data) {
            body.data = JSON.stringify(data);
        }
        const options = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `bearer ${Auth.getToken()}`,
            },
            body: JSON.stringify({ data, ...variables }),
        };
        const url = `/api/update-all-lines-data`;
        try {
            const res = await fetch(url, options);
            if (res.status == 200) {
                const resData = await res.json();
                // return should be dates by type
                //console.log('result', resData);
                afterUpdate(resData?.dates);
                this.setState({ data: null });
            } else {
                const errors = res.errors ? res.errors : {};
                errors.summary = res.message;
                console.error(errors);
            }
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const { classes, type, typeLabel } = this.props;
        const { selectedWeek, data } = this.state;
        const jsonData = data ? JSON.stringify(data) : '';
        return (
            <Card style={{ margin: 12 }}>
                <CardHeader className={classes.cardHeader}>{typeLabel}</CardHeader>
                {/* <CardContent style={{ padding: 12, display: 'flex', flexDirection: 'column' }}>
                    Updated:
                    <DateFormat date={actualLastUpdate} />
                </CardContent> */}
                <CardContent
                    style={{ padding: 12, paddingTop: 0, display: 'flex', flexDirection: 'column' }}
                >
                    <FootballWeeks
                        selectedWeek={selectedWeek}
                        onWeekChange={this.handleSelectedWeekChange}
                    />
                    <form className={classes.root} noValidate autoComplete="off">
                        <textarea
                            className={classes.textarea}
                            name="data"
                            value={jsonData}
                            onChange={(e) => this.handleInputChange(e)}
                        ></textarea>
                    </form>
                    <Button color="primary" onClick={() => this.updateAll()}>
                        Update All
                    </Button>
                </CardContent>
            </Card>
        );
    }
}

export const AllSourcesUpdater = withStyles(uploaderStyles, { withTheme: true })((props) => {
    return <AllSourcesUpdaterBase {...props} type="all" typeLabel="All Lines Sources" />;
});
