import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        maxWidth: 475,
        textAlign: 'center',
    },
    teamLogo: {
        width: 55,
    },
    teamContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

export default withStyles(styles, { withTheme: true })(({ team, classes }) => {
    return (
        <div className={classes.teamContainer}>
            <img src={`/images/teams/${team.abbrev}.svg`} className={classes.teamLogo} />
            <Typography variant="subheading">{team.name}</Typography>
        </div>
    );
});
