import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class BetTypeToggler extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { handleChange, spreads, totals } = this.props;
        return (
            <FormGroup style={{ marginLeft: 25 }} row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={spreads}
                            onChange={handleChange}
                            value="spreads"
                            name="showSpreads"
                            color="primary"
                        />
                    }
                    label="Spreads"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={totals}
                            onChange={handleChange}
                            value="totals"
                            name="showTotals"
                        />
                    }
                    label="Totals"
                />
            </FormGroup>
        );
    }
}

export default BetTypeToggler;
