import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import classNames from 'classnames';

const styles = (theme) => ({
    image: {
        width: 40,
        borderRadius: 8,
    },
    weatherContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        //marginLeft: '20px',
    },
    day: {
        color: '#fff',
        background: 'linear-gradient(30deg, #246fa8 0%, #57c1eb 100%)',
    },

    night: {
        color: '#fff',
        background: 'linear-gradient(30deg, #20202c 0%, #515175 100%)',
    },
    temp: {
        fontFamily: 'Google Sans,Roboto,arial,sans-serif',
        fontWeight: '400',
        color: '#202124',
        display: 'inline',
        fontSize: 27,
        //line-height 56px,
    },
    degrees: {
        fontFamily: 'Google Sans,Roboto,arial,sans-serif',
        fontWeight: '400',
        color: '#202124',
        display: 'inline',
        fontSize: 14,
        marginTop: 4,
        //line-height 56px,
    },
    inside: {
        marginTop: 8,
    },
    tempContainer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    weatherText: {
        fontSize: '.85em',
        textAlign: 'left',
    },
});

const keyWordWeatherMap = [
    {
        term: 'thunderstorm',
        imageName: 'thunderstorm',
    },
    {
        term: 'rain',
        imageName: 'rain',
    },
    {
        term: 'mostly cloudy',
        imageName: 'mostly_cloudy',
    },
    {
        term: 'partly cloudy',
        imageName: 'partly_cloudy',
    },
    {
        term: 'fog',
        imageName: 'fog',
    },
    {
        term: 'overcast',
        imageName: 'overcast',
    },
    {
        term: 'clear',
        imageName: 'clear',
    },
];

function isNight(dateTime) {
    //moment('2010-10-20').isAfter('2010-01-01', 'hour');
    const hourOfDay = moment(dateTime).hour();
    return hourOfDay > 17;
}

function getImageName(weatherNote, dateTime, dome) {
    if (dome) {
        return `dome.webp`;
    }
    const imageObj = keyWordWeatherMap.find((o) => weatherNote?.toLowerCase().includes(o.term));
    if (imageObj) {
        const { imageName } = imageObj;
        return isNight(dateTime) ? imageName + '_n.png' : imageName + '.png';
    }
    return `default_weather.webp`;
}

function weather({ weather, dateTime, classes }) {
    if (!(weather && weather?.temperature)) {
        return (
            <div className={classes.weatherContainer}>
                <Typography variant="caption">No weather info</Typography>
            </div>
        );
    }
    const { weatherNote, windSpeed, windDirection, temperature, dome } = weather;
    const night = isNight(dateTime);
    const imgClasses = classNames({
        [classes.image]: true,
        [classes.night]: night,
        [classes.day]: !night,
    });
    const weatherImgName = getImageName(weatherNote, dateTime, dome);
    const imageSrc = `/images/weather/${weatherImgName}`;
    const tempparts = temperature.split(' ');
    return (
        <div className={classes.weatherContainer}>
            <Grid container direction="row" spacing={16} justify="center">
                <Grid xs={5} item container direction="row" spacing={8} justify="center">
                    <Grid item>
                        <img src={imageSrc} className={imgClasses} />
                    </Grid>
                    <Grid item className={classes.tempContainer}>
                        {dome ? (
                            <React.Fragment>
                                <span></span>
                                <span className={classes.inside}>Inside</span>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <span className={classes.temp}>{tempparts[0]}</span>
                                <span className={classes.degrees}>{tempparts[1]}</span>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
                {dome ? null : (
                    <Grid xs={6} item container direction="column">
                        <Grid item>
                            <Typography variant="caption" className={classes.weatherText}>
                                {weatherNote}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" className={classes.weatherText}>
                                wind:{windDirection} {windSpeed}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

/*

*/

export const Weather = withStyles(styles, { withTheme: true })(weather);
// function weather({ team, classes }) {
//     return (
//         <div className={classes.teamContainer}>
//             <img src={`images/teams/${team.abbrev}.svg`} className={classes.teamLogo} />
//             <Typography variant="subheading">{team.name}</Typography>
//         </div>
//     );
// }
