import gql from 'graphql-tag';

import { WAGER_INFO } from '../Fragment/Wager';
import { LINE_INFO } from '../Fragment/Lines';

export const GET_META = gql`
    query metaDetails($year: Int!, $week: Int!) {
        metaDetails(year: $year, week: $week) {
            eid
            announcers
            date
            matchup
            tv
        }
    }
`;

export const GET_WAGERS_BY_WEEK = gql`
    query wagersByWeek($year: Int!, $week: Int!) {
        wagersByWeek(year: $year, week: $week) {
            ...wagerInfo
        }
    }
    ${WAGER_INFO}
`;

export const GET_WAGERS_FOR_REPORTS = gql`
    query wagers($year: Int!, $startWeek: Int!, $endWeek: Int!) {
        wagers(year: $year, startWeek: $startWeek, endWeek: $endWeek) {
            ...wagerInfo
        }
    }
    ${WAGER_INFO}
`;

export const GET_LINES = gql`
    {
        nflByDate(date: "2018-08-25") {
            id
            name
            date
            homeTeam {
                partid
                teamId
                city
                isHome
                name
                abbrev
            }
            awayTeam {
                partid
                teamId
                city
                isHome
                name
                abbrev
            }
            linesByBook {
                paid
                book {
                    name
                    url
                    image
                }
                teamsLine {
                    over {
                        ...lineInfo
                    }
                    homeSpread {
                        ...lineInfo
                    }
                    under {
                        ...lineInfo
                    }
                    awaySpread {
                        ...lineInfo
                    }
                }
            }
        }
    }
    ${LINE_INFO}
`;

export const ADD_WAGER_MUTATION = gql`
    mutation addWager($wagerInput: AddWagerInput!) {
        addWager(input: $wagerInput) {
            ...wagerInfo
        }
    }
    ${WAGER_INFO}
`;

export const GET_TRACKING_BY_TYPE = gql`
    query trackingDetails($type: String!) {
        trackingDetails(type: $type) {
            type
            lastUpdate
        }
    }
`;

export const GET_MATCHUP = gql`
    query getEvent($eid: Int!) {
        getEvent(eid: $eid) {
            name
            date
            hasWager
            weather {
                weatherNote
                windSpeed
                windDirection
                temperature
                dome
            }
            homeTeam {
                partid
                teamId
                city
                isHome
                name
                abbrev
            }
            awayTeam {
                partid
                teamId
                city
                isHome
                name
                abbrev
            }
        }
    }
`;

export const TEAM_INJURIES = gql`
    query teamInjuries($teamId: Int!, $year: Int!, $week: Int!) {
        teamInjuries(teamId: $teamId, year: $year, week: $week) {
            playerName
            type
            position
            status
            updated
            teamName
            osnps
            osnps_ly
            dsnps
            dsnps_ly
        }
    }
`;
// here is would be interest to have things like fumbles/int/yds etc
// also to transform more fields into team_perspective (i.e. anything prefixed with home/away)
export const TEAM_GAMELOG_NFL_SCHED = gql`
    query teamGamelog($abbrev: String!, $year: Int!, $week: Int!) {
        teamGamelog(abbrev: $abbrev, year: $year, week: $week) {
            team
            opp_team
            game_type
            week
            gameday
            weekday
            gametime
            away_score
            home_score
            away_rest
            home_rest
            home_qb_name
            away_qb_name
        }
    }
`;
export const TEAM_GAMELOG = gql`
    query teamGamelog($abbrev: String!, $year: Int!, $week: Int!) {
        teamGamelog(abbrev: $abbrev, year: $year, week: $week) {
            team_id
            opp_team
            abbrev
            opp_abbrev
            game_id
            season
            day
            rest
            result
            is_home
            week
            dist
            pf
            pa
            sum_ep
            to
            opp_to
            ep_o
            ep_d
            ep_st
            yds
            p_yd
            r_yd
            opp_yds
            opp_p_yd
            opp_d_yd
            surface
            temp
            wind
            roof
            gamedate
            gametime
        }
    }
`;

// team {
//     name
//     abbrev
// }
export const GET_LINES_BY_DATE = gql`
    query nflDateGrouped($year: Int!, $week: Int!) {
        nflDateGrouped(year: $year, week: $week) {
            date
            events {
                id
                name
                date
                hasWager
                weather {
                    weatherNote
                    windSpeed
                    windDirection
                    temperature
                    dome
                }
                homeTeam {
                    partid
                    teamId
                    city
                    isHome
                    name
                    abbrev
                }
                awayTeam {
                    partid
                    teamId
                    city
                    isHome
                    name
                    abbrev
                }
                linesByBook {
                    paid
                    book {
                        name
                        url
                        image
                    }
                    teamsLine {
                        over {
                            ...lineInfo
                        }
                        homeSpread {
                            ...lineInfo
                        }
                        under {
                            ...lineInfo
                        }
                        awaySpread {
                            ...lineInfo
                        }
                    }
                }
            }
        }
    }
    ${LINE_INFO}
`;
