import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardContent';
import TeaserIcon from 'mdi-material-ui/FormatText';
import moment from 'moment';
import _ from 'lodash';
import { PickContent } from './PickDetails';
import { getResultColor } from './Helpers';
import casual from 'casual-browserify';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        overflow: 'hidden',
        //backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        maxWidth: 600,
        //height: 450,
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    gridListTile: {
        // borderColor: 'rgba(0, 0, 0, 0.25)',
        // borderBottom:1,
        // borderStyle:'solid'
        //backgroundColor: 'rgba(0, 0, 0, 0.01)',
        backgroundColor: theme.palette.background.paper,
        margin: 8,
        marginTop: 0,
        //padding:4
    },
});

const wagerCardStyles = (theme) => ({
    root: {
        //maxWidth: 300,
        //minWidth: 250,
        //minHeight:148,
        width: 300,
        //center card in page
        //margin: '0 auto',
        //center text in card
        textAlign: 'center',
        padding: 4,
        borderRadius: '10px ',
    },
    actions: {
        display: 'flex',
        //justifyContent: 'flex-end',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 8,
    },
    teaserLabel: {
        display: 'flex',
        alignItems: 'center',
        //justifyContent: 'space-between',
    },
    teaserIcon: {
        fontSize: 16,
    },
    dateLine: {},
    headerRoot: {
        padding: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
});

const teaserFriendlyName = (seed) => {
    casual.seed(seed);
    return casual.first_name;
    //return casual.color_name;
};

const WagerScheduleCard = withStyles(wagerCardStyles, { withTheme: true })(({ classes, pick }) => {
    let { date, wagerResult } = pick;
    const mDate = moment(date);
    let formmattedTime = mDate.format('h:mm A');
    let rowColor = getResultColor(wagerResult);
    return (
        <Card className={classes.root} style={{ backgroundColor: rowColor }}>
            <CardHeader className={classes.headerRoot}>
                <Typography variant="caption" className={classes.dateLine}>
                    {formmattedTime}
                </Typography>
                <Typography variant="caption">
                    {pick.wagerType === 'T' && (
                        <div className={classes.teaserLabel}>
                            <TeaserIcon className={classes.teaserIcon} />{' '}
                            {teaserFriendlyName(pick.wagerId)}
                        </div>
                    )}
                </Typography>
            </CardHeader>
            <PickContent hideDate={true} pick={pick} />
            <Divider light />
            <CardActions className={classes.actions} disableActionSpacing>
                <Typography variant="caption">{pick.meta && pick.meta.announcers}</Typography>
                <Typography variant="caption">DTV: {pick.meta && pick.meta.channel}</Typography>
                {/* <Typography variant="caption">{selection.teaserAmt}</Typography> */}
            </CardActions>
            {pick.meta && pick.meta.tv && (
                <CardActions className={classes.actions} disableActionSpacing>
                    <Typography variant="caption">TV: {pick.meta.tv}</Typography>
                </CardActions>
            )}
        </Card>
    );
});

const WagersForDay = withStyles(styles, { withTheme: true })(({ date, mDate, picks, classes }) => {
    let formattedDate = mDate.format('dddd (M/D) - h A');
    return (
        <Grid container style={{ marginBottom: 34 }}>
            <Grid item style={{ flexBasis: '90%' }}>
                <ListSubheader color="primary" component="div">
                    {formattedDate}
                </ListSubheader>
            </Grid>
            <Grid container direction="row" spacing={8} justify="flex-start" alignItems="center">
                {picks.map((pick) => (
                    <Grid item key={pick.id} xs={12} sm={6} md={4}>
                        <WagerScheduleCard pick={pick} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
});

const WagerScheduleGridList = (props) => {
    const { pickGroups } = props;
    return (
        <React.Fragment>
            {pickGroups.map(({ picks, mDate }, idx) => (
                <WagersForDay key={idx} mDate={mDate} picks={picks} {...props} />
            ))}
        </React.Fragment>
    );
};

WagerScheduleGridList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(WagerScheduleGridList);
