import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    root: {
        display: 'inline-flex',
        marginLeft: 10,
    },
    //   container: {
    //     display: 'flex',
    //     flexWrap: 'wrap',
    //   },
    //   textField: {
    //     marginLeft: theme.spacing.unit,
    //     marginRight: theme.spacing.unit,
    //     width: 100,
    //   },
    //   menu: {
    //     width: 200,
    //   },
    //   button: {
    //     margin: theme.spacing.unit,
    //   },
});

const Record = ({ classes, record }) => (
    <Typography variant="caption" className={classes.root}>
        {record.W} - {record.L} - {record.T} (U:{record.U} total:{record.total})
    </Typography>
);

export default withStyles(styles, { withTheme: true })(Record);
