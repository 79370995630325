import React from 'react';
import Button from '@material-ui/core/Button';
import Auth from '../../modules/Auth';

class ResetCacheButton extends React.Component {
    //constructor(props) {}

    resetCache() {
        console.log('resetting cache');
        const options = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `bearer ${Auth.getToken()}`,
            },
            //credentials: 'include',
        };
        const url = `/api/reset-cache`;
        fetch(url, options)
            .then((r) => {
                //const token = r.headers.get('x-auth-token');
                //console.log("response", r)
                if (r.status == 200) {
                    r.json().then((data) => {
                        console.log('data reset', data);
                        alert('Event Cache Reset\n', JSON.stringify(data));
                    });
                } else {
                    const errors = r.errors ? r.errors : {};
                    errors.summary = r.message;
                    console.log(errors);
                }
            })
            .catch((e) => console.error(e));
    }

    render() {
        return (
            <Button color="primary" size="small" onClick={this.resetCache}>
                Reset Event Cache
            </Button>
        );
    }
}

export default ResetCacheButton;
