import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing.unit,
    },
});

const GET_BETS = gql`
    {
        bets {
            id
            description
        }
    }
`;

const UPDATE_BET = gql`
    mutation updateBet($id: Int!, $description: String!) {
        updateBet(id: $id, description: $description) {
            id
            description
        }
    }
`;

const ADD_BET = gql`
    mutation addBet($description: String!) {
        addBet(description: $description) {
            id
            description
        }
    }
`;

class EditBet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            betDesc: this.props.description,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        // this.renderTextInput = this.renderTextInput.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    // onSubmit = (addBet) => {
    //   //   e.preventDefault();
    //   //   addBet({ variables: { description: this.input.value } });
    // }

    render() {
        let { classes, updateBet, id } = this.props;
        let { betDesc } = this.state;
        //let input;
        const submit = (e) => {
            e.preventDefault();
            let qParams = { variables: { id, description: betDesc } };
            updateBet(qParams);
        };

        return (
            <div>
                <form onSubmit={submit}>
                    <TextField
                        required
                        id="required"
                        label="Required"
                        className={classes.textField}
                        margin="normal"
                        name="betDesc"
                        value={this.state.betDesc}
                        onChange={this.handleInputChange}
                    />
                    <Button
                        type="submit"
                        variant="outlined"
                        color="secondary"
                        className={classes.button}
                    >
                        Update Bet
                    </Button>
                    {/* <button description="submit">Update Bet</button> */}
                </form>
            </div>
        );
    }
}

const Bets = ({ classes }) => (
    <Query query={GET_BETS}>
        {({ loading, error, data }) => {
            if (loading) return <p>Loading...</p>;
            if (error) return <p>Error :(</p>;

            return data.bets.map(({ id, description }) => {
                let input;
                let EditBetProps = { classes, id, description };
                return (
                    <Mutation mutation={UPDATE_BET} key={id}>
                        {(updateBet, { loading, error }) => (
                            <div>
                                <EditBet {...EditBetProps} updateBet={updateBet} />
                                {loading && <p>Loading...</p>}
                                {error && <p>Error :( Please try again</p>}
                            </div>
                        )}
                    </Mutation>
                );
            });
        }}
    </Query>
);

class AddBet extends Component {
    // makeRef = node => {
    //   this.input = node;
    // };

    constructor(props) {
        super(props);
        this.state = {
            isGoing: true,
            numberOfGuests: 2,
            betDesc: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.renderTextInput = this.renderTextInput.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    // onSubmit = (addBet) => {
    //   //   e.preventDefault();
    //   //   addBet({ variables: { description: this.input.value } });
    // }

    renderTextInput(addBet) {
        let { classes } = this.props;
        //let input;
        const submit = (e) => {
            e.preventDefault();
            addBet({ variables: { description: this.state.betDesc } });
            this.setState({ betDesc: '' });
        };

        return (
            <div>
                <form onSubmit={submit}>
                    <TextField
                        required
                        id="required"
                        label="Required"
                        //defaultValue="Hello World"
                        className={classes.textField}
                        margin="normal"
                        //inputRef={this.makeRef}
                        name="betDesc"
                        value={this.state.betDesc}
                        onChange={this.handleInputChange}
                    />
                    <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        Add Bet
                    </Button>
                    {/* <button description="submit"></button> */}
                </form>
            </div>
        );
    }

    render() {
        return (
            <Mutation
                mutation={ADD_BET}
                update={(cache, { data: { addBet } }) => {
                    const { bets } = cache.readQuery({ query: GET_BETS });
                    cache.writeQuery({
                        query: GET_BETS,
                        data: { bets: bets.concat([addBet]) },
                    });
                }}
            >
                {this.renderTextInput}
            </Mutation>
        );
    }
}

// const AddBet = ({classes}) => {
//   //let input;
//   //let txtInput;
//   return (
//     <Mutation
//       mutation={ADD_BET}
//       update={(cache, { data: { addBet } }) => {
//         const { bets } = cache.readQuery({ query: GET_BETS });
//         cache.writeQuery({
//           query: GET_BETS,
//           data: { bets: bets.concat([addBet]) }
//         });
//       }}
//     >
//       {addBet => {
//         let input;
//         const submit = (e) => {
//             e.preventDefault();
//             addBet({ variables: { description: input.value } });
//             input.value = "";
//         };
//         return (
//         <div>
//           <form
//             onSubmit={submit}
//           >
//             <TextField
//                 required
//                 id="required"
//                 label="Required"
//                 //defaultValue="Hello World"
//                 className={classes.textField}
//                 margin="normal"
//                 inputProps={{ref: node => input = node}}
//                 // inputProps={{
//                 //     ref: node => {input = node}
//                 // }}
//             />
//             {/* <input
//               ref={node => {
//                 input = node;
//               }}
//             /> */}
//             <button description="submit">Add Bet</button>
//           </form>
//         </div>
//       )}}
//     </Mutation>
//   );
// };

const BetsPage = ({ classes }) => (
    <div>
        <h2>Manage Bets</h2>
        <AddBet classes={classes} />
        <Bets classes={classes} />
    </div>
);

export default withStyles(styles, { withTheme: true })(BetsPage);

// class Reservation extends React.Component {

//   render() {
//     return (
//       <form>
//         <label>
//           Is going:
//           <input
//             name="isGoing"
//             type="checkbox"
//             checked={this.state.isGoing}
//             onChange={this.handleInputChange} />
//         </label>
//         <br />
//         <label>
//           Number of guests:
//           <input
//             name="numberOfGuests"
//             type="number"
//             value={this.state.numberOfGuests}
//             onChange={this.handleInputChange} />
//         </label>
//       </form>
//     );
//   }
// }
// submit = e => {
//   e.preventDefault();
//   this.props.onSubmit(this.input.value);
// };
// render() {
//   return (
//     <form onSubmit={this.submit}>
//       {this.props.value}
//       <TextField
//         onChange={this.props.onChange}
//         //inputProps={{ ref: node => console.log('2', node) }}
//         inputRef={this.makeRef}
//       />
//       <button type="submit">Go</button>
//     </form>
//   );
//}

// <div>
// {/* <p>{description}</p> */}
// <form
//   onSubmit={e => {
//     e.preventDefault();
//     let qParams = { variables: { id, description: input.value } };
//     updateBet(qParams);
//   }}
// >
//   <input
//       defaultValue={description}
//     ref={node => {
//       input = node;
//     }}
//   />
//   <button description="submit">Update Bet</button>
// </form>
