import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import Auth from '../modules/Auth';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import FacebookIcon from 'mdi-material-ui/Facebook';
import GoogleIcon from 'mdi-material-ui/Google';

const styles = (theme) => ({
    button: {
        margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
});

const onFailure = (response) => console.error(response);

const authResponse = (response, history, toggleAuthenticateStatus, provider) => {
    const options = {
        method: 'POST',
        body: JSON.stringify({ access_token: response.accessToken }),
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
    };
    //todo: need to handle case of token= false (i.e. when there is some server side error)
    console.log(options);
    let url = `/api2/v1/auth/${provider}`;
    fetch(url, options)
        .then((r) => {
            //const token = r.headers.get('x-auth-token');
            console.log('response', r);
            if (r.status == 200) {
                r.json().then((data) => {
                    //todo: need to handle token false here
                    Auth.authenticateUser(data.token, data.user);
                    toggleAuthenticateStatus(data.user);
                    history.push('/dashboard');
                });
            }
        })
        .catch((e) => console.error(e));
};

const facebookSocialButton = ({ toggleAuthenticateStatus, history, classes }) => (
    <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email,picture"
        callback={(response) =>
            authResponse(response, history, toggleAuthenticateStatus, 'facebook')
        }
        render={(renderProps) => (
            <Button variant="contained" onClick={renderProps.onClick} className={classes.button}>
                <FacebookIcon className={classes.leftIcon} /> Facebook
            </Button>
        )}
    />
);

const googleSocialButton = ({ toggleAuthenticateStatus, history, classes }) => (
    <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onSuccess={(response) =>
            authResponse(response, history, toggleAuthenticateStatus, 'google')
        }
        onFailure={onFailure}
        render={(renderProps) => (
            <Button variant="contained" onClick={renderProps.onClick} className={classes.button}>
                <GoogleIcon className={classes.leftIcon} /> Google
            </Button>
        )}
    />
);

export const FacebookSocialButton = withStyles(styles, { withTheme: true })(facebookSocialButton);
export const GoogleSocialButton = withStyles(styles, { withTheme: true })(googleSocialButton);
