import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { GoogleSocialButton, FacebookSocialButton } from './LoginSocialButtons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

const LoginForm = ({
    onSubmit,
    onChange,
    errors,
    successMessage,
    user,
    toggleAuthenticateStatus,
    history,
}) => {
    let socialProps = { toggleAuthenticateStatus, history };
    return (
        <Card className="container">
            <form action="/" onSubmit={onSubmit}>
                <h2 className="card-heading">Login</h2>

                {successMessage && <p className="success-message">{successMessage}</p>}
                {errors.summary && <p className="error-message">{errors.summary}</p>}

                <div className="field-line">
                    <TextField
                        label="Email"
                        name="email"
                        helperText={errors.email}
                        onChange={onChange}
                        value={user.email}
                    />
                </div>

                <div className="field-line">
                    <TextField
                        label="Password"
                        type="password"
                        name="password"
                        onChange={onChange}
                        helperText={errors.password}
                        value={user.password}
                    />
                </div>

                <div className="button-line">
                    <Button type="submit" variant="contained" color="primary">
                        Log in
                    </Button>
                </div>
                {/* <CardText>Use social Login! <Link to={'/login_social'}>Social Login</Link>.</CardText> */}
                <div className="button-line">
                    <GoogleSocialButton {...socialProps} />
                    <FacebookSocialButton {...socialProps} />
                </div>
                <CardContent>
                    Don't have an account? <Link to={'/signup'}>Create one</Link>.
                </CardContent>
            </form>
        </Card>
    );
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    successMessage: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
};

export default LoginForm;
