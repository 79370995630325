import gql from 'graphql-tag';

import { TEAM_INFO, TEAM_INPUT } from './Team';

export const WAGER_INFO = gql`
    fragment wagerInfo on Wager {
        id
        paid
        amount
        odds
        type
        entered
        result
        book {
            name
            url
            image
        }
        picks {
            id
            selectedPartId
            againstPartId
            type
            subType
            adjValue
            date
            result
            homeTeam {
                ...teamInfo
            }
            awayTeam {
                ...teamInfo
            }
            meta {
                announcers
                channel
                tv
            }
        }
    }
    ${TEAM_INFO}
`;

export const WAGER_INPUT = gql`
    fragment wagerInputData on AddWagerInput {
        paid
        amount
        odds
        type
        picks {
            paid
            selectedPartId
            againstPartId
            type
            subType
            adjValue
            date
            lineid
            eid
            homeTeam {
                ...teamInput
            }
            awayTeam {
                ...teamInput
            }
            lineData {
                eid
                partid
                paid
                lineid
                value
                odds
                type
                subType
            }
        }
    }
    ${TEAM_INPUT}
`;
