import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { HtmlUploaderBase } from './HtmlUpdaterBase';

const uploaderStyles = (theme) => ({
    cardHeader: {
        color: theme.palette.getContrastText(orange[400]),
        backgroundColor: orange[400],
        padding: 6,
    },
    textarea: {
        width: 200,
        height: 50,
        padding: 8,
        boxSizing: 'border-box',
        border: '2px solid #ccc',
        borderRadius: 4,
        backgroundColor: '#f8f8f8',
        fontSize: 16,
        // resize: 'none',
    },
});

export const HeritageUpdater = withStyles(uploaderStyles, { withTheme: true })((props) => {
    return <HtmlUploaderBase {...props} type="heritage" typeLabel="Heritage" />;
});

// if we need to b64 enconde unicode
// function base64ToBytes(base64) {
//     const binString = atob(base64);
//     return Uint8Array.from(binString, (m) => m.codePointAt(0));
// }

// function bytesToBase64(bytes) {
//     const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join('');
//     return btoa(binString);
// }

// Usage
//   bytesToBase64(new TextEncoder().encode("a Ā 𐀀 文 🦄")); // "YSDEgCDwkICAIOaWhyDwn6aE"
//   new TextDecoder().decode(base64ToBytes("YSDEgCDwkICAIOaWhyDwn6aE")); // "a Ā 𐀀 文 🦄"
