import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ResetCacheButton from '../Util/ResetCacheButton';
import { Query } from 'react-apollo';
import GameCard from './GameCard';
import Grid from '@material-ui/core/Grid';
import { GET_LINES_BY_DATE } from '../Query';
import FootballWeeks from '../Util/FootballWeeks';
import { NFL_SEASON } from '../Util/FootballWeeks';
import DateFormat from '../Util/DateFormat';
import moment from 'moment';
import { withTheme } from '@material-ui/core/styles';

import ToggleChip from '../Util/ToggleChip';
import BetTypeToggler from './BetTypeToggler';
import DateFilterChips from '../Filters/DateFilterChips';
import QueryProgressIndicator from '../Util/QueryProgressIndicator';
import ListSubheader from '@material-ui/core/ListSubheader';

const styles = (theme) => ({
    gridList: {},
    dateHeader: {
        fontSize: 16,
    },
    dateContainer: {
        //width: '100%',
        //maxWidth: 1200,
        backgroundColor: '#eaecf5',
        padding: 10,
        paddingBottom: 15,
        paddingTop: 15,
        marginTop: 10,
        marginBottom: 10,
    },
});

const GameFilters = withTheme()(({ toggleFilter, gameFilters, ...rest }) => {
    return (
        <React.Fragment>
            <DateFilterChips toggleFilter={toggleFilter} filters={gameFilters} {...rest} />
            <ToggleChip
                isOn={gameFilters.pastGames}
                onLabel="Past Games"
                name="pastGames"
                offLabel="Show Past Games"
                onToggle={toggleFilter}
            />
            <ToggleChip
                isOn={gameFilters.betGames}
                name="betGames"
                onLabel="Games w/Bets"
                offLabel="Games w/Bets"
                onToggle={toggleFilter}
            />
        </React.Fragment>
    );
});

const filterGames = (groupedGames, gameFilters) => {
    let { pastGames, betGames, ...dayFilters } = gameFilters;
    //let days = Object.key(dayFilters);
    //{nflDateGrouped.map(({events, date}, idx) => (
    //first delete any days that are not wanted
    let filtered = groupedGames.filter((grp) => {
        let dayKey = moment(grp.date).format('dddd').toLowerCase();
        return dayFilters[dayKey]; //i.e. ==true means we want that one
    });
    if (!pastGames) {
        let current = moment();
        filtered = filtered.map((grp) => {
            //grp.events.forEach(e=> console.log(moment(e).isAfter(current)));
            //console.log(grp.events)
            return {
                events: grp.events.filter((e) => moment(e.date).isAfter(current)),
                //events:grp.events,
                date: grp.date,
            };
        });
    }
    let hideBetGames = !betGames;
    if (hideBetGames) {
        filtered = filtered.map((grp) => {
            return {
                events: grp.events.filter((e) => !e.hasWager),
                date: grp.date,
            };
        });
    }
    return filtered;
};

const GamesList = withStyles(styles, { withTheme: true })(({ classes, user, ...rest }) => {
    let { selectedWeek, showSpreads, toggleSpreadsTotals, showTotals } = rest;
    // let week = NFL_SEASON[selectedWeek - 1];
    // let startDate = week.dates[0].toDate();

    const weekObj = NFL_SEASON[selectedWeek - 1];
    const year = weekObj.dates[0].year();
    const week = selectedWeek;
    const queryVariables = { year, week };
    // old vars{ date: startDate }

    return (
        <Query query={GET_LINES_BY_DATE} variables={queryVariables}>
            {({ loading, error, data }) => {
                if (loading) return <QueryProgressIndicator />;
                if (error) return <p>Error :(</p>;
                let { nflDateGrouped } = data;

                let filteredGroups = filterGames(nflDateGrouped, rest.gameFilters);
                const daysAvailableForFilter = nflDateGrouped.map((g) =>
                    moment(g.date).format('dddd').toLowerCase()
                );
                return (
                    <React.Fragment>
                        <Grid item>
                            <FootballWeeks {...rest} />{' '}
                            <GameFilters availableDates={daysAvailableForFilter} {...rest} />
                        </Grid>
                        <Grid item style={{ display: 'flex' }}>
                            <BetTypeToggler
                                handleChange={toggleSpreadsTotals}
                                spreads={showSpreads}
                                totals={showTotals}
                            />
                            <ResetCacheButton />
                        </Grid>
                        {filteredGroups.map(({ events, date }, idx) => (
                            <React.Fragment key={idx}>
                                <Grid item style={{ flexBasis: '90%' }}>
                                    <ListSubheader color="primary" component="div">
                                        <DateFormat date={date} />
                                    </ListSubheader>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    spacing={16}
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    {events.map((game, idx) => (
                                        <Grid item key={idx}>
                                            <GameCard key={game.id} game={game} {...rest} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                );
            }}
        </Query>
    );
});

export default GamesList;
