import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from 'mdi-material-ui/DeleteOutline';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        //width: '100%',
    },
});

class DeleteConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    handleDelete = (e) => {
        this.handleClose();
        this.props.onDelete(e);
    };

    render() {
        let confirmMsg = this.props.confirmMsg || 'Delete this item?';
        let children = this.props.children;
        return (
            <React.Fragment>
                {this.props.children ? (
                    <Button onClick={this.handleClickOpen}>{this.props.children}</Button>
                ) : (
                    <IconButton onClick={this.handleClickOpen}>
                        <DeleteIcon />
                    </IconButton>
                )}
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {confirmMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>Cancel</Button>
                        <Button onClick={this.handleDelete} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

//export default DeleteConfirmation;
export default withStyles(styles, { withTheme: true })(DeleteConfirmation);
