import gql from 'graphql-tag';

export const LINE_INFO = gql`
    fragment lineInfo on SBLine {
        odds
        paid
        lineid
        type
        subType
        value
        partid
        hasWager
        wagerType
        wagerAmount
    }
`;
