class Auth {
    /**
     * Authenticate a user. Save a token string in Local Storage
     *
     * @param {string} token
     */
    static authenticateUser(token, user = {}) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
    }

    /**
     * Check if a user is authenticated - check if a token is saved in Local Storage
     *
     * @returns {boolean}
     */
    static isUserAuthenticated() {
        return localStorage.getItem('token') !== null;
    }

    static isUserAdmin(user = {}) {
        let isAdmin =
            Auth.isUserAuthenticated() && user && user.roles && user.roles.includes('admin');
        return isAdmin;
    }

    /**
     * Deauthenticate a user. Remove a token from Local Storage.
     *
     */
    static deauthenticateUser() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }

    /**
     * Get a token value.
     *
     * @returns {string}
     */

    static getToken() {
        return localStorage.getItem('token');
    }

    //this is a hack for now, need to review/tighten up user handling
    //this would show higher levels of auth on 'de-auth' for example
    static getUser() {
        let user = localStorage.getItem('user');
        if (user) {
            let data = JSON.parse(user);
            return data;
        }
    }
}

export default Auth;
