import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FootballWeeks, { getDefaultWeek, NFL_SEASON } from '../Util/FootballWeeks';
import Grid from '@material-ui/core/Grid';
import { GET_META } from '../Query';
import { UPDATE_ANNOUNCERS, UPDATE_CHANNELS } from '../Mutation';
import { Query } from 'react-apollo';
import DateFormat from '../Util/DateFormat';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

import { ApolloConsumer } from 'react-apollo';
import QueryProgressIndicator from '../Util/QueryProgressIndicator';
import EditTV from './EditTV';

const styles = (theme) => ({
    root: {},
    divider: {
        //background:'#fafafa'
        background: theme.palette.secondary.dark,
        height: 6,
        opacity: 0.25,
    },
});

const MetaTable = withStyles(styles, { withTheme: true })(({ classes, week, year }) => {
    return (
        <Query query={GET_META} variables={{ year, week }} fetchPolicy="network-only">
            {({ loading, error, data }) => {
                if (loading) return <QueryProgressIndicator />;
                if (error) return <p>Error :(</p>;
                let { metaDetails } = data;
                let prevDate;
                return (
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Matchup</TableCell>
                                <TableCell>Announcers</TableCell>
                                <TableCell>TV</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {metaDetails.map((m, idx) => {
                                let mDate = moment(m.date);
                                let curDate = mDate.format('ddd hA');
                                let showDivider = prevDate && curDate != prevDate;
                                prevDate = curDate;
                                let divider = showDivider ? (
                                    <TableRow className={classes.divider}>
                                        <TableCell colSpan={5}></TableCell>
                                    </TableRow>
                                ) : null;
                                return (
                                    <React.Fragment key={m.eid}>
                                        {divider}
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <DateFormat date={m.date} />
                                            </TableCell>
                                            <TableCell>{m.matchup}</TableCell>
                                            <TableCell>{m.announcers}</TableCell>
                                            <TableCell>
                                                <EditTV eid={m.eid} tv={m.tv} />
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                );
            }}
        </Query>
    );
});

class MetaUpdater extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedWeek: getDefaultWeek(),
        };

        this.handleSelectedWeekChange = this.handleSelectedWeekChange.bind(this);
        //this.updateMetaData = this.updateMetaData.bind(this)
    }

    handleSelectedWeekChange(week) {
        this.setState({
            selectedWeek: week,
        });
    }

    afterQueryUpdate = () => {
        this.forceUpdate();
    };

    render() {
        const { classes } = this.props;
        const { selectedWeek } = this.state;
        let weekObj = NFL_SEASON[selectedWeek - 1];
        let year = weekObj.dates[0].year();
        let propsForUpdates = {
            week: selectedWeek,
            year,
        };
        return (
            <Grid container direction="column">
                <Grid item>
                    <h3>Update Metadata</h3>
                </Grid>
                <Grid item>
                    <FootballWeeks
                        selectedWeek={selectedWeek}
                        onWeekChange={this.handleSelectedWeekChange}
                    />
                    {/* <UpdateMetaButton {...propsForUpdates} afterUpdate={this.afterQueryUpdate} /> */}
                </Grid>
                <Grid item>
                    <MetaTable {...propsForUpdates} />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(MetaUpdater);

// old meta button
// const executeUpdataMeta = async (client, year, week, mutation) => {
//     let variables = {
//         year,
//         week,
//     };

//     let mutationParams = {
//         mutation,
//         variables,
//     };

//     let results = await client.mutate(mutationParams);
//     return results;
// };

// const UpdateMetaButton = ({ year, week, afterUpdate }) => {
//     const updateMetaData = async (client) => {
//         return Promise.all([executeUpdataMeta(client, year, week, UPDATE_ANNOUNCERS)])
//             .then((allResults) => {
//                 afterUpdate();
//                 console.log(allResults);
//             })
//             .catch((err) => console.error(err));
//     };

//     return (
//         <ApolloConsumer>
//             {(client) => (
//                 <Button
//                     color="primary"
//                     onClick={() => {
//                         updateMetaData(client);
//                     }}
//                 >
//                     Update
//                 </Button>
//             )}
//         </ApolloConsumer>
//     );
// };
