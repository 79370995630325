import React from 'react';
// import { Card, CardTitle, CardText } from 'material-ui/Card';
import Auth from '../modules/Auth';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
class HomePage extends React.Component {
    componentDidMount() {
        // update authenticated state on logout
        let user = Auth.getUser();
        this.props.toggleAuthenticateStatus(user);
    }

    render() {
        return (
            <Card className="container">
                <CardContent>
                    <Typography variant="headline">React Application</Typography>
                    <Typography color="textSecondary">This is the HomePage</Typography>

                    {Auth.isUserAuthenticated() ? (
                        <div style={{ fontSize: '16px', color: 'green' }}>
                            Welcome! You are logged in.
                        </div>
                    ) : (
                        <div style={{ fontSize: '16px', color: 'green' }}>
                            You are not logged in.
                        </div>
                    )}
                </CardContent>
            </Card>
        );
    }
}

export default HomePage;
