import moment from 'moment';
import _ from 'lodash';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';

export const transformToPicks = (wagers) => {
    //1. flatten while noting results of wager
    //2. order by date
    //3. group by date
    // need to filter?
    let pickArrays = wagers.map((w) => {
        return w.picks.map((p) => {
            //ugg this is to get around the apollo typing non-extensible thing
            return Object.assign({}, p, {
                wagerResult: w.result,
                wagerType: w.type,
                wagerId: w.id, //for identifying partner teasters for now
            });
        });
    });
    let flattened = [].concat.apply([], pickArrays);
    flattened.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    return flattened;
};

export const transformToPickGroups = (wagers) => {
    let flattened = transformToPicks(wagers);
    let dateGroupedPicks = _.groupBy(flattened, (p) => {
        return moment(p.date).startOf('hour');
    });
    let groupedAsArray = _.map(dateGroupedPicks, (picks, dateKey) => {
        return { mDate: moment(dateKey), picks };
    });
    return groupedAsArray;
};

export const getResultColor = (wagerResult) => {
    let rowColor;
    if (wagerResult) {
        rowColor = wagerResult == 'W' ? green[50] : wagerResult == 'L' ? red[50] : orange[50];
    }
    return rowColor;
};

export function getTeaserResultColor(pickIdx, picks = []) {
    //console.log(pickIdx, picks);
    // if just one, then it is simple
    if (picks?.length === 1) {
        const { result } = picks[pickIdx];
        if (result) {
            return getResultColor(result);
        }
    }
    if (picks?.length > 1) {
        // if every one has a result, we can use red/green/orange, else we'll use red/blue
        if (picks.every((result) => result)) {
            if (picks.every(({ result }) => result === 'W')) {
                return green[50];
            }
            if (picks.some(({ result }) => result === 'L')) {
                return red[50];
            }
            if (picks.every(({ result }) => result === 'T' || result === 'W')) {
                return orange[50];
            }
            // else some combo are undecided and we haven't lost yet
            const pick = picks[pickIdx];
            const teaserItemResult = pick?.result;
            if (teaserItemResult) {
                if (teaserItemResult === 'W') {
                    return blue[50];
                }
                if (teaserItemResult === 'T') {
                    return orange[50];
                }
            }
        }
    }

    // let rowColor;
    // if (wagerResult) {
    //     rowColor = wagerResult == 'W' ? green[50] : wagerResult == 'L' ? red[50] : orange[50];
    // }
    // return rowColor;
}
