import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { PicksContent } from './PickDetails';
import Divider from '@material-ui/core/Divider';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from 'mdi-material-ui/DeleteOutline';
import SaveIcon from 'mdi-material-ui/CloudCheck';
import EditIcon from 'mdi-material-ui/CircleEditOutline';
import EnteredIcon from 'mdi-material-ui/MarkerCheck';
import Button from '@material-ui/core/Button';

import Snackbar from '@material-ui/core/Snackbar';
import green from '@material-ui/core/colors/green';
//import { GET_WAGERS } from '../Query'
import { UPDATE_PICK } from '../Mutation';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 50,
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing.unit,
    },
    cardRoot: {
        maxWidth: 325,
        //center text in card
        textAlign: 'center',
        margin: 20,
    },
    cardActions: {
        display: 'flex',
        //justifyContent: 'flex-end',
        justifyContent: 'space-between',
        padding: 0,
    },
    editContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    cardContent: {
        paddingTop: 0,
    },
    menuSpacer: {
        marginRight: 16,
        width: 24,
    },
});

const gradeControlStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
});

export const GradeControls = withStyles(gradeControlStyles)(({ classes, result, handleChange }) => {
    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                    row
                    aria-label="result"
                    name="result"
                    className={classes.group}
                    value={result}
                    onChange={handleChange}
                >
                    <FormControlLabel value="U" control={<Radio />} label="None" />
                    <FormControlLabel value="W" control={<Radio />} label="W" />
                    <FormControlLabel value="L" control={<Radio />} label="L" />
                    <FormControlLabel value="T" control={<Radio />} label="P" />
                </RadioGroup>
            </FormControl>
        </div>
    );
});

class EditGrade extends Component {
    constructor(props) {
        super(props);

        let { result } = this.props.pick;
        this.state = {
            openSnack: false,
            result: result || 'U',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event, updatePick) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
        this.savePick(event, updatePick, value);
    }

    handleSnackClose = (event, reason) => {
        this.setState({ openSnack: false });
    };
    // we'll do this async for now we so don't have a weird ui experience
    // look into optimistic response as well
    savePick = (e, updatePick, value) => {
        e.preventDefault();
        let { id } = this.props.pick;
        let qParams = {
            variables: {
                updateWagerPickInput: {
                    id,
                    result: value,
                },
            },
        };
        updatePick(qParams);
    };

    render() {
        let { classes } = this.props;
        let { result } = this.state;
        let { id } = this.props.pick;
        return (
            <Mutation
                mutation={UPDATE_PICK}
                key={id}
                update={(cache, { data: { updatePick } }) => {
                    this.setState({ openSnack: true });
                }}
            >
                {(updatePick, { loading, error }) => (
                    <div>
                        {loading && <p>Loading...</p>}
                        {error && <p>Error :( Please try again</p>}
                        <GradeControls
                            result={result}
                            handleChange={(e) => this.handleInputChange(e, updatePick)}
                        />
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={this.state.openSnack}
                            onClose={this.handleSnackClose}
                            autoHideDuration={1500}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">Pick Saved</span>}
                        />
                    </div>
                )}
            </Mutation>
        );
    }
}

export default withStyles(styles, { withTheme: true })(EditGrade);

//   <form onSubmit={(e) => this.submitForm(e, updatePick)}>
//                  <Card className={classes.cardRoot}>
//                   <WagerHeader book={book} >
//                       <IconButton
//                         aria-owns={anchorEl ? 'more-menu' : null}
//                         aria-haspopup="true"
//                         onClick={this.handleMenuOpen}
//                       >
//                         <MoreVertIcon />
//                       </IconButton>
//                       <Menu
//                         id="more-menu"
//                         anchorEl={anchorEl}
//                         open={Boolean(anchorEl)}
//                         onClose={this.handleMenuClose}
//                       >
//                         <MenuItem onClick={(e) => this.submitForm(e, updatePick)}>
//                           <ListItemIcon>
//                               <SaveIcon />
//                           </ListItemIcon>Save
//                         </MenuItem>
//                         <MenuItem onClick={this.toggleEditMode}>
//                           <ListItemIcon>
//                               <EditIcon />
//                           </ListItemIcon>
//                             Edit
//                         </MenuItem>
//                       </Menu>
//                   </WagerHeader>
//                   <CardContent className={classes.cardContent}>
//                     <Grid container spacing={0}>
//                       <Grid item xs={12}>
//                         {/* <PickHeader></PickHeader> */}
//                         <PicksContent editMode={editMode} picks={picks} onChange={this.handlePickEdit} />
//                       </Grid>
//                       {!editMode &&
//                       <Grid item>
//                         ${amount}
//                         {entered &&
//                           <EnteredIcon color="secondary" style={{paddingTop:2, marginLeft:8,fontSize:16}}/>
//                         }
//                       </Grid>
//                       }
//                       {editMode &&
//                       <Grid item container direction="row" spacing={8}>
//                         <Grid item xs={6} >
//                           <TextField
//                                   onFocus={e => e.target.select()}
//                                   required
//                                   id="amount"
//                                   label="Amt"
//                                   className={classes.textField}
//                                   name="amount"
//                                   value={amount}
//                                   onChange={this.handleInputChange}
//                                   />

//                           <TextField
//                                   onFocus={e => e.target.select()}
//                                   required
//                                   id="odds"
//                                   label="Odds"
//                                   className={classes.textField}
//                                   name="odds"
//                                   value={odds}
//                                   onChange={this.handleInputChange}
//                                   />
//                         </Grid>
//                         <Grid item xs={6} md={6}>
//                           <FormControlLabel
//                             control={<Checkbox checked={entered} color="secondary" name="entered" onChange={this.handleInputChange}/>}
//                             label="Entered"
//                           />

//                         </Grid>
//                       </Grid>
//                       }
//                     </Grid>
//                   </CardContent>
//                   <Divider light />
//                   {editMode &&
//                   <CardActions className={classes.cardActions} disableActionSpacing>

//                       <Button onClick={this.handleCancelEdit}>Cancel</Button>
//                       <IconButton aria-label="Save" color="primary" type="submit">
//                         <SaveIcon />
//                       </IconButton>
//                   </CardActions>
//                   }
//                   </Card>
//                   <Snackbar
//                     anchorOrigin={{
//                       vertical: 'bottom',
//                       horizontal: 'left',
//                     }}
//                     open={this.state.openSnack}
//                     onClose={this.handleSnackClose}
//                     autoHideDuration={1500}
//                     ContentProps={{
//                       'aria-describedby': 'message-id',
//                     }}
//                     message={<span id="message-id">Wager Saved</span>}
//                   />
//                 </form>
