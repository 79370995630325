import React from 'react';
import PropTypes from 'prop-types';
import Auth from '../modules/Auth';
import LoginForm from '../components/LoginForm';

class LoginPage extends React.Component {
    /**
     * Class constructor.
     */
    constructor(props, context) {
        super(props, context);

        const storedMessage = localStorage.getItem('successMessage');
        let successMessage = '';

        if (storedMessage) {
            successMessage = storedMessage;
            localStorage.removeItem('successMessage');
        }

        // set the initial component state
        this.state = {
            errors: {},
            successMessage,
            user: {
                email: '',
                password: '',
            },
        };

        // this.processForm = this.processForm.bind(this);
        this.processForm2 = this.processForm2.bind(this);
        this.changeUser = this.changeUser.bind(this);
    }

    processForm2(e) {
        e.preventDefault();

        // create a string for an HTTP body message const email =
        // encodeURIComponent(this.state.user.email); const password =
        // encodeURIComponent(this.state.user.password);

        const postData = {
            email: this.state.user.email,
            password: this.state.user.password,
        };

        /*-------------------- */
        const options = {
            method: 'POST',
            body: JSON.stringify(postData),
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        };
        // todo: need to handle case of token= false (i.e. when there is some server side
        // error)
        let url = `/auth/login`;
        fetch(url, options)
            .then((r) => {
                //const token = r.headers.get('x-auth-token');
                //console.log("response", r)
                if (r.status == 200) {
                    r.json().then((data) => {
                        console.log(data);
                        this.setState({ errors: {} });
                        //todo: need to handle token false here
                        Auth.authenticateUser(data.token, data.user);
                        this.props.toggleAuthenticateStatus(data.user);
                        this.props.history.push('/dashboard');
                    });
                } else {
                    const errors = r.errors ? r.errors : {};
                    errors.summary = r.message;

                    this.setState({ errors });
                }
            })
            .catch((e) => console.error(e));
    }

    /**
     * Change the user object.
     *
     * @param {object} event - the JavaScript event object
     */
    changeUser(event) {
        const field = event.target.name;
        const user = this.state.user;
        user[field] = event.target.value;

        this.setState({ user });
    }

    /**
     * Render the component.
     */
    render() {
        return (
            <LoginForm
                onSubmit={this.processForm2}
                onChange={this.changeUser}
                errors={this.state.errors}
                successMessage={this.state.successMessage}
                user={this.state.user}
                {...this.props}
            />
        );
    }
}

LoginPage.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default LoginPage;
