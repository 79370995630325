import React from 'react';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const chipStyles = (theme) => ({
    root: {},
    chip: {
        marginLeft: 10,
    },
});

//ternary pattern i like
const ToggleChip = withStyles(chipStyles, { withTheme: true })(
    ({ classes, isOn, onLabel, offLabel, onToggle, name }) => {
        let toggler = () => onToggle(name);
        return isOn ? (
            <Chip
                label={onLabel}
                onDelete={toggler}
                onClick={toggler}
                color="primary"
                className={classes.chip}
            />
        ) : (
            <Chip label={offLabel} onClick={toggler} className={classes.chip} />
        );
    }
);

export default ToggleChip;
