import Table from '@material-ui/core/Table';
import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { TEAM_GAMELOG } from '../Query';
import QueryProgressIndicator from '../Util/QueryProgressIndicator';
import { getDefaultYear, getDefaultWeek } from '../Util/FootballWeeks';
import grey from '@material-ui/core/colors/grey';

const styles = (theme) => ({
    divider: {
        //background:'#fafafa'
        background: theme.palette.primary.dark,
        height: 3,
        opacity: 0.5,
        margin: 4,
    },
    table: {
        fontSize: '.75rem',
        maxWidth: 800,
        '& tr:nth-child(even)': {
            background: grey[50],
        },
    },
    tableCell: {
        fontSize: '.8rem',
    },
    // tableRow: {
    //     background: lightBlue[300],
    // },
    // tableBody: {

    // },
});

export const Gamelog = withStyles(styles, { withTheme: true })(({ classes, abbrev }) => {
    const queryVariables = {
        abbrev,
        year: getDefaultYear(),
        week: getDefaultWeek(),
    };
    return (
        <Query query={TEAM_GAMELOG} variables={queryVariables}>
            {({ loading, error, data }) => {
                if (loading) return <QueryProgressIndicator />;
                if (error) return <p>Error :(</p>;
                const { teamGamelog } = data;
                const abbrev = teamGamelog?.length ? teamGamelog[0].abbrev : null;
                // console.log(linesByBook); undefined
                return (
                    <React.Fragment>
                        <div>{abbrev} Gamelog</div>
                        <div className={classes.divider}></div>
                        <Table className={classes.table} size="small" padding="checkbox">
                            <TableHead>
                                <TableRow>
                                    <TableCell>opp</TableCell>
                                    <TableCell>date</TableCell>
                                    <TableCell>wk</TableCell>
                                    <TableCell>day</TableCell>
                                    <TableCell>time</TableCell>
                                    <TableCell>pf</TableCell>
                                    <TableCell>pa</TableCell>
                                    <TableCell>rs</TableCell>
                                    <TableCell>sumEp</TableCell>
                                    <TableCell>to</TableCell>
                                    <TableCell>o_to</TableCell>
                                    <TableCell>yds</TableCell>
                                    <TableCell>o_yd</TableCell>
                                    <TableCell>rest</TableCell>
                                    <TableCell>dist</TableCell>
                                    <TableCell>surface</TableCell>
                                    <TableCell>temp</TableCell>
                                    <TableCell>wind</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {teamGamelog.map((game, idx) => {
                                    const {
                                        opp_abbrev,
                                        gamedate,
                                        week,
                                        day,
                                        gametime,
                                        pf,
                                        pa,
                                        result,
                                        sum_ep,
                                        to,
                                        opp_to,
                                        yds,
                                        opp_yds,
                                        rest,
                                        dist,
                                        surface,
                                        temp,
                                        wind,
                                        roof,
                                    } = game;
                                    const showDivider = false;
                                    let divider = showDivider ? (
                                        <TableRow className={classes.divider}>
                                            <TableCell colSpan={5}></TableCell>
                                        </TableRow>
                                    ) : null;
                                    return (
                                        <React.Fragment key={idx}>
                                            {divider}
                                            <TableRow>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    className={classes.tableCell}
                                                >
                                                    {opp_abbrev}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {gamedate}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {week}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {day}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {gametime}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {pf}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {pa}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {result}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {sum_ep}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {to}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {opp_to}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {yds}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {opp_yds}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {rest}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {dist}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {surface}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {temp}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {wind}
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </React.Fragment>
                );
            }}
        </Query>
    );
});
