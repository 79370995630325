import React from 'react';
import moment from 'moment';
import { withTheme } from '@material-ui/core/styles';
import { NFL_SEASON } from '../Util/FootballWeeks';
import ToggleChip from '../Util/ToggleChip';

const DateFilterChips = withTheme()(
    ({ availableDates = [], toggleFilter, filters, selectedWeek }) => {
        let week = NFL_SEASON[selectedWeek - 1];
        let datesWithData = week.dates.filter((m) =>
            availableDates.includes(m.format('dddd').toLowerCase())
        );
        return (
            <React.Fragment>
                {datesWithData.map((d, idx) => (
                    <ToggleChip
                        key={idx}
                        isOn={filters[d.format('dddd').toLowerCase()]}
                        name={d.format('dddd').toLowerCase()}
                        onLabel={d.format('ddd - M/D')}
                        offLabel={d.format('ddd - M/D')}
                        onToggle={toggleFilter}
                    />
                ))}
            </React.Fragment>
        );
    }
);

export default DateFilterChips;
