import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
//import CoinIcon from 'mdi-material-ui/Coin';
import AtIcon from 'mdi-material-ui/At';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import DateFormat from '../Util/DateFormat';
import Team from './Team';
import { BookLines } from './BookLines';
import { Weather } from './Weather';

import { grey } from '@material-ui/core/colors';

// todo could add badge for which book has active teaser and how many
const styles = (theme) => ({
    root: {
        maxWidth: 606,
        //center card in page
        //margin: '0 auto',
        marginLeft: 8,
        //center text in card
        textAlign: 'center',
        borderRadius: 20,
    },
    teamsContent: {
        display: 'flex',
        alignItems: 'center',
        padding: 8,
        textWrap: 'nowrap',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingTop: 0,
    },
    cardActions: {
        padding: '4px 4px',
    },
    atIcon: {
        fontSize: 20,
    },
    teamWrapper: {
        flex: 45,
    },
    cardHeader: {
        color: theme.palette.getContrastText(grey[200]),
        backgroundColor: grey[200],
        padding: 2,
    },
});

function openInNewTab(eid) {
    const url = `./games/matchup?eid=${eid}`;
    window.open(url, '_blank').focus();
}

class GameCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { showSpreads, showTotals, game, user, classes, ...rest } = this.props;
        const { homeTeam, awayTeam, weather } = game;
        const extraLineProps = {
            date: game.date,
            eid: game.id,
            showSpreads,
            showTotals,
            homeTeam,
            awayTeam,
            ...rest,
        };
        return (
            <Card className={classes.root}>
                <CardHeader className={classes.cardHeader}>
                    <Typography color="primary" style={{ fontSize: 14 }}>
                        <DateFormat date={game.date} />
                    </Typography>
                </CardHeader>
                <CardContent className={classes.teamsContent}>
                    <div className={classes.teamWrapper}>
                        <Team team={awayTeam} />
                    </div>
                    <div style={{ flex: 10 }}>
                        <AtIcon className={classes.atIcon} />
                    </div>
                    <div className={classes.teamWrapper}>
                        <Team team={homeTeam} />
                    </div>
                </CardContent>
                <CardContent style={{ padding: 4 }}>
                    <Weather weather={weather} />
                </CardContent>
                <Divider />
                <CardActions disableActionSpacing style={{ paddingTop: 0 }}>
                    <List dense={true} className={classes.list}>
                        {game.linesByBook.map((line, idx, ary) => (
                            <BookLines
                                {...extraLineProps}
                                index={idx}
                                length={ary.length}
                                key={line.paid}
                                bookLine={line}
                            />
                        ))}
                    </List>
                </CardActions>
                <CardActions disableActionSpacing style={{ padding: '0 16px' }}>
                    <List dense={true} className={classes.list}>
                        <Button variant="text" size="small" onClick={() => openInNewTab(game.id)}>
                            Matchup
                        </Button>
                    </List>
                </CardActions>
            </Card>
        );
    }
}

export default withStyles(styles, { withTheme: true })(GameCard);

{
    /* <Button variant="text" size="small" onClick={() => openInNewTab(123)}>
Matchup
</Button> */
}
