import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import _ from 'lodash';

const formatSpread = (spread) => {
    if (spread > 0) {
        return '+' + spread;
    } else {
        return spread.toString();
    }
};

const NFL_YEAR = 2024;

const getNFLSeason = () => {
    // const PRE_SEASON_START_DATE = `${NFL_YEAR}-08-08`;
    const SEASON_START_DATE = `${NFL_YEAR}-09-05`;
    //const SEASON_START_DATE = PRE_SEASON_START_DATE;
    // for each week, we start on thur and end on monday, we want only
    // we want only those dates
    const nflSeason = [];
    let pi = 2;
    // for (pi; pi < 5; pi++) {
    //     nflSeason.push({
    //         display: `Preseason Week ${pi}`,
    //     });
    // }

    let i = 1;
    for (i; i < 19; i++) {
        nflSeason.push({
            display: `Week ${i}`,
        });
    }
    ['WildCard', 'Divisional', 'Conf Champ', 'Probowl', 'SuperBowl'].forEach((w) =>
        nflSeason.push({ display: `${w}` })
    );
    let weekStartDate = moment(SEASON_START_DATE);
    nflSeason.forEach((w) => {
        let start = weekStartDate.clone();
        w.dates = [start];
        _.times(4, (n) => {
            let newDate = start.clone();
            newDate.add(n + 1, 'd');
            w.dates.push(newDate);
        });
        weekStartDate.add(1, 'w');
    });
    return nflSeason;
};

export const NFL_SEASON = getNFLSeason();

export const getDefaultWeek = () => {
    let today = moment();
    //we want the first week where todays date is less than or equal to the final date;
    let selectedWeek =
        NFL_SEASON.findIndex((w) => {
            let lastDateInWeek = w.dates[w.dates.length - 1];
            return today.isSameOrBefore(lastDateInWeek);
        }) + 1;
    return selectedWeek;
};

export function getDefaultYear() {
    return NFL_YEAR;
}

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

const FootballWeeks = withStyles(styles, { withTheme: true })(
    class extends Component {
        constructor(props) {
            super(props);

            // this.state = {
            //     week: selectedWeek || 1, //in the case of out of season, this should make it fall back to 1
            // };
            this.handleInputChange = this.handleInputChange.bind(this);
        }
        handleInputChange(event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            this.props.onWeekChange(value);
            //   this.setState({
            //     [name]: value
            //   });
        }

        handleChange = (event) => {
            this.setState({ [event.target.name]: event.target.value });
        };

        render() {
            const { classes } = this.props;

            return (
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="week-simple">Week</InputLabel>
                    <Select
                        value={this.props.selectedWeek}
                        onChange={this.handleInputChange}
                        inputProps={{
                            name: 'week',
                            id: 'week-simple',
                        }}
                    >
                        {NFL_SEASON.map((w, idx) => (
                            <MenuItem key={idx} value={idx + 1}>
                                {w.display}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        }
    }
);

export default FootballWeeks;
