import React from 'react';
import Typography from '@material-ui/core/Typography';

const formatSpread = (spread) => {
    if (spread > 0) {
        return '+' + spread;
    }
    return spread?.toString();
};

const LineValue = ({ value, subType, type, variant }) => {
    let formattedVal;
    if (type == 'total') {
        formattedVal = `${subType.charAt(0).toUpperCase()}${value}`;
    } else {
        formattedVal = formatSpread(value);
    }
    return <Typography variant={variant}>{formattedVal}</Typography>;
};

export default LineValue;
