import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import TeaserIcon from 'mdi-material-ui/FormatText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LineValue from '../Util/LineValue';
import classNames from 'classnames';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';

const styles = (theme) => ({
    buttonIcon: {
        fontSize: 20,
    },
    button: {
        margin: theme.spacing.unit - 4,
        borderRadius: 50,
        height: 52,
        width: 52,
        minWidth: 'unset',
    },
    buttonSelectedWager: {
        color: theme.palette.getContrastText(orange[200]),
        backgroundColor: orange[200],
        '&:hover': {
            backgroundColor: orange[700],
        },
    },
    wong: {
        // backgroundColor: '#3d9e6824',
        color: theme.palette.getContrastText(green[50]),
        backgroundColor: green[50],
    },
});

export const ButtonTeaser = withStyles(styles, { withTheme: true })(
    class ButtonTeaserSimple extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                anchorEl: null,
            };
            this.teaserRates = [6, 6.5, 7];
        }
        handleClick = (event) => {
            this.setState({ anchorEl: event.currentTarget });
        };

        handleClose = () => {
            this.setState({ anchorEl: null });
        };

        handleSelection(teaserAmt) {
            this.handleClose();
            const { type, subType, value } = this.props.line;
            const isTeasedOver = subType === 'over';
            const teasedValue = isTeasedOver ? value - teaserAmt : value + teaserAmt;
            let teasedLine = Object.assign({}, this.props.line, {
                teasedValue,
                teaserAmt,
            });
            this.props.onClick(teasedLine);
        }

        getTeasedValue = (teaserAmt) => {
            let { value, subType } = this.props.line;
            if (subType == 'over') {
                //invert Teaser amt
                teaserAmt = -1 * teaserAmt;
            }
            let teasedValue = value + teaserAmt;
            return teasedValue;
        };
        render() {
            const { anchorEl } = this.state;
            const { classes } = this.props;
            const { type, subType, hasWager, wagerType, value } = this.props.line;
            const lineValueProps = { type, subType };
            let buttonClassAry = [classes.button];
            const hasTeaserWager = hasWager && wagerType == 'T';
            if (hasTeaserWager) {
                buttonClassAry.push(classes.buttonSelectedWager);
            }
            if (!hasTeaserWager && isWong(value)) {
                buttonClassAry.push(classes.wong);
            }
            // const listItemClasses = classNames({
            //     [classes.wong]: isWong(bookLine?.teamsLine?.awaySpread?.value),
            //     [classes.listItemRoot]: true,
            // });

            let buttonClasses = classNames(buttonClassAry);
            return (
                <div>
                    <Button
                        variant="outlined"
                        aria-owns={anchorEl ? 'teaser-menu' : null}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        className={buttonClasses}
                    >
                        <TeaserIcon className={classes.buttonIcon} />
                    </Button>
                    <Menu
                        id="teaser-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                    >
                        {this.teaserRates.map((teaserAmt, idx) => (
                            <MenuItem key={idx} onClick={() => this.handleSelection(teaserAmt)}>
                                <ListItemText>
                                    <LineValue
                                        value={this.getTeasedValue(teaserAmt)}
                                        {...lineValueProps}
                                    />
                                </ListItemText>
                                <ListItemText inset secondary={`(${teaserAmt})`} />
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            );
        }
    }
);

// could go to util
function isWong(spread) {
    //and to only play spread favorites of -7.5 to -8.5 and underdogs from +1.5 to +2.5.
    if (!spread) {
        return false;
    }

    return (spread > 1 && spread < 3) || (spread < -7 && spread > -9);
}
