import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import moment from 'moment';
import { Query } from 'react-apollo';
import QueryProgressIndicator from '../Util/QueryProgressIndicator';
import { transformToPicks } from '../Wagers/Helpers';

import { GET_WAGERS_FOR_REPORTS } from '../Query';

//import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BookLogoLink from '../Util/BookLogoLink';

import { Link } from 'react-router-dom';

import { AdminRoute } from '../Util/RouteUtil';

const tableStyles = (theme) => ({
    // root: {
    //   width: '100%',
    //   marginTop: theme.spacing.unit * 3,
    //   overflowX: 'auto',
    // },
    smallTable: {
        maxWidth: 400,
    },
    firstCol: {
        borderLeft: '1px solid black',
    },
});

const RecordTable = withStyles(tableStyles, { withTheme: true })(({ classes, rows }) => {
    return (
        // <Table className={classes.table}>
        <Table padding="dense">
            <TableHead>
                <TableRow>
                    <TableCell rowSpan={2}>Team </TableCell>
                    <TableCell colSpan={5}>Overall</TableCell>
                    <TableCell colSpan={4}>Teaser</TableCell>
                    <TableCell colSpan={4}>Straight</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Tot</TableCell>
                    <TableCell>W</TableCell>
                    <TableCell>L</TableCell>
                    <TableCell>P</TableCell>
                    <TableCell>U</TableCell>
                    <TableCell className={classes.firstCol}>W</TableCell>
                    <TableCell>L</TableCell>
                    <TableCell>P</TableCell>
                    <TableCell>U</TableCell>
                    <TableCell className={classes.firstCol}>W</TableCell>
                    <TableCell>L</TableCell>
                    <TableCell>P</TableCell>
                    <TableCell>U</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => {
                    return (
                        <TableRow key={row.teamId}>
                            <TableCell component="th" scope="row">
                                {row.teamName}
                            </TableCell>
                            <TableCell numeric>{row.recordTotals.total}</TableCell>
                            <TableCell numeric>{row.recordTotals.W}</TableCell>
                            <TableCell numeric>{row.recordTotals.L}</TableCell>
                            <TableCell numeric>{row.recordTotals.T}</TableCell>
                            <TableCell numeric>{row.recordTotals.U}</TableCell>

                            <TableCell numeric className={classes.firstCol}>
                                {row.recordTeaser.W}
                            </TableCell>
                            <TableCell numeric>{row.recordTeaser.L}</TableCell>
                            <TableCell numeric>{row.recordTeaser.T}</TableCell>
                            <TableCell numeric>{row.recordTeaser.U}</TableCell>

                            <TableCell numeric className={classes.firstCol}>
                                {row.recordStraight.W}
                            </TableCell>
                            <TableCell numeric>{row.recordStraight.L}</TableCell>
                            <TableCell numeric>{row.recordStraight.T}</TableCell>
                            <TableCell numeric>{row.recordStraight.U}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
});

const BooksTable = withStyles(tableStyles, { withTheme: true })(({ classes, rows }) => {
    return (
        // <Table className={classes.table}>
        <Table className={classes.smallTable} padding="dense">
            <TableHead>
                <TableRow>
                    <TableCell>Book </TableCell>
                    <TableCell>Count</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>W/L</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => {
                    return (
                        <TableRow key={row.bookId}>
                            <TableCell component="th" scope="row">
                                <BookLogoLink book={row.book} />
                            </TableCell>
                            <TableCell numeric>{row.count}</TableCell>
                            <TableCell numeric>{row.wagered}</TableCell>
                            <TableCell numeric>{row.wonLost}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
});

// SimpleTable.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// export default withStyles(styles)(SimpleTable);

//import Grid from '@material-ui/core/Grid';
const ReportContainer = ({ children, queryVariables }) => {
    return (
        <Paper style={{ padding: 8, margin: 30 }}>
            Weeks {queryVariables.startWeek} - {queryVariables.endWeek}
            {children}
        </Paper>
    );
};

const styles = (theme) => ({
    root: {
        display: 'inline-flex',
        marginLeft: 10,
    },
});

//another thing that should be in helpers
// Create our number formatter.
//https://stackoverflow.com/a/16233919
const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    // the default value for minimumFractionDigits depends on the currency
    // and is usually already 2
}).format;

const calcTotals = (wagers) => {
    const books = _(wagers)
        .groupBy('paid')
        .map((bookWagers, id) => ({
            bookId: id,
            book: bookWagers[0].book,
            count: bookWagers.length,
            wagered: _.sumBy(bookWagers, 'amount'),
            wonLost: _.sumBy(bookWagers, (w) => {
                let retval = 0;
                if (w.result && w.result !== 'U') {
                    if (w.result === 'P') {
                        //retval = w.amount;
                    } else if (w.result === 'L') {
                        retval = -1 * w.amount;
                    } else if (w.result === 'W') {
                        //retval = w.amount;
                        let winAmount = 0;
                        if (w.odds == 0) {
                            winAmount = w.amount;
                        } else {
                            if (w.odds > 0) {
                                winAmount = w.amount * (w.odds / 100);
                            } else {
                                //not neg 100 to flip the sign
                                winAmount = w.amount * (-100 / w.odds);
                            }
                        }
                        retval = winAmount;
                    }
                }
                return retval;
            }),
        }))
        .value();
    books.forEach((b) => {
        //b.wonLost = //Math.round(b.wonLost * 100) / 100
        b.wonLost = dollarFormatter(b.wonLost);
        b.wagered = dollarFormatter(b.wagered);
    });
    //console.log(books);
    return books;
};

const getTeamName = (
    { type, subType, homeTeam, awayTeam, selectedPartId, againstPartId },
    useAgainst
) => {
    let partId = useAgainst ? againstPartId : selectedPartId;
    let formattedSelectionVal;
    if (type == 'total') {
        formattedSelectionVal = subType;
    } else if (homeTeam.partid == partId) {
        formattedSelectionVal = homeTeam.name;
    } else if (awayTeam.partid == partId) {
        formattedSelectionVal = awayTeam.name;
    } else {
        formattedSelectionVal = 'ERROR';
    }
    return formattedSelectionVal;
};

const mutateRecord = (row, pick, fieldName, wagerType = 'all') => {
    //teamRow = { teamName, teamId, W:0, L:0, T:0, U:0, total:0 };
    if (!row[fieldName]) {
        row[fieldName] = { W: 0, L: 0, T: 0, U: 0, total: 0 };
    }
    let addResult = wagerType == 'all' || pick.wagerType == wagerType;
    if (addResult) {
        let r = row[fieldName];
        r.total = r.total + 1;
        r.W = r.W + Number(pick.result === 'W');
        r.L = r.L + Number(pick.result === 'L');
        r.T = r.T + Number(pick.result === 'T');
        r.U = r.U + Number(pick.result === null);
    }
    return row;
};

const calcTeams = (wagers, fieldToUse = 'selectedPartId') => {
    let picks = transformToPicks(wagers);
    //let fieldToUse = "selectedPartId";
    let teams = picks.reduce((data, pick, idx) => {
        let teamId = pick[fieldToUse];
        let teamRow = data.find((r) => r.teamId === teamId);
        let useAgainst = fieldToUse == 'againstPartId';
        let teamName = getTeamName(pick, useAgainst);
        if (!teamRow) {
            teamRow = { teamName, teamId };
            data.push(teamRow);
            //teamRow = data.find(r => r.teamId === teamId);
        }
        if (teamRow.teamName !== teamName) {
            console.error('UH OH, TEAM MISMATCH');
        }
        mutateRecord(teamRow, pick, 'recordTotals');
        mutateRecord(teamRow, pick, 'recordTeaser', 'T');
        mutateRecord(teamRow, pick, 'recordStraight', 'S');

        // teamRow.total = teamRow.total + 1;
        // teamRow.W = teamRow.W + Number(pick.result==="W");
        // teamRow.L = teamRow.L + Number(pick.result==="L");
        // teamRow.T = teamRow.T + Number(pick.result==="T");
        // teamRow.U = teamRow.U + Number(pick.result===null);
        return data;
    }, []);
    // var sortedArray = _.orderBy(mixedArray,
    //   ['foo','foo.bar','bar.foo.bar'],
    //   ['desc','asc','desc']);
    return _.orderBy(teams, ['recordTotals.L', 'recordTotals.W'], ['desc', 'asc']);
};

const ReportTotal = ({ wagers, queryVariables }) => {
    let booksData = calcTotals(wagers);
    return (
        <div style={{ maxWidth: 500 }}>
            <ReportContainer queryVariables={queryVariables}>
                <Typography>Totals by Book</Typography>
                <BooksTable rows={booksData} />
            </ReportContainer>
        </div>
    );
};

const ReportFor = ({ wagers, queryVariables }) => {
    let teamsFor = calcTeams(wagers);
    //console.log(teamsFor);
    return (
        <ReportContainer queryVariables={queryVariables}>
            <Typography>Teams For Report</Typography>
            <div>
                <RecordTable rows={teamsFor} />
            </div>
        </ReportContainer>
    );
};

const ReportAgainst = ({ wagers, queryVariables }) => {
    let teamsAgainst = calcTeams(wagers, 'againstPartId');
    //console.log(teamsAgainst);
    return (
        <ReportContainer queryVariables={queryVariables}>
            <Typography>Teams Against Report</Typography>
            <RecordTable rows={teamsAgainst} />
        </ReportContainer>
    );
};

const ReportsMenu = ({ classes }) => {
    // const { selectedTab, selectedWeek, wagerFilters } = this.state;
    // const weekObj = NFL_SEASON[selectedWeek-1];
    // const year = weekObj.dates[0].year();
    // const week = selectedWeek;
    const year = 2021;
    const startWeek = 1;
    const endWeek = 17;
    const queryVariables = { year, startWeek, endWeek };
    return (
        <React.Fragment>
            <div style={{ marginLeft: 25 }}>
                <Link to={'/dashboard/total'}>Total by Book</Link> |{' '}
                <Link to={'/dashboard/betfor'}>For Teams</Link> |{' '}
                <Link to={'/dashboard/betagainst'}>Against Teams</Link>
            </div>
            <Query query={GET_WAGERS_FOR_REPORTS} variables={queryVariables}>
                {({ loading, error, data }) => {
                    if (loading) return <QueryProgressIndicator />;
                    if (error) return <p>Error :(</p>;
                    return (
                        <React.Fragment>
                            <AdminRoute
                                path="/dashboard/total"
                                component={ReportTotal}
                                wagers={data.wagers}
                                queryVariables={queryVariables}
                            />
                            <AdminRoute
                                path="/dashboard/betfor"
                                component={ReportFor}
                                wagers={data.wagers}
                                queryVariables={queryVariables}
                            />
                            <AdminRoute
                                path="/dashboard/betagainst"
                                component={ReportAgainst}
                                wagers={data.wagers}
                                queryVariables={queryVariables}
                            />
                        </React.Fragment>
                    );
                }}
            </Query>
        </React.Fragment>
    );
};

export default withStyles(styles, { withTheme: true })(ReportsMenu);
