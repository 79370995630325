import React, { Component } from 'react';
import Auth from '../../modules/Auth';

import {
    Route,
    Redirect,
    //withRouter
} from 'react-router-dom';

const userIsAdmin = () => {
    // return (this.state.isAdmin && this.state.authenticated);
    let user = Auth.getUser();
    return user && Auth.isUserAuthenticated() && Auth.isUserAdmin(user);
};

export const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            userIsAdmin() ? (
                <Component {...props} {...rest} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            Auth.isUserAuthenticated() ? (
                <Component {...props} {...rest} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

export const LoggedOutRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            Auth.isUserAuthenticated() ? (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location },
                    }}
                />
            ) : (
                <Component {...props} {...rest} />
            )
        }
    />
);

export const PropsRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
);
