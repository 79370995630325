import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Matchup } from './Games/Matchup';
import {
    //  BrowserRouter as Router,
    Route,
    Link,
    //Redirect,
    withRouter,
} from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
// todo maybe add switcher here, could should mini cards or just have dropdown
// todo create scraper for ATS by team, just update once a week and store that somewhere
// https://www.pro-football-reference.com/teams/buf/2022_lines.htm

const styles = (theme) => ({
    root: {
        width: '85%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginLeft: 100,
        maxWidth: '80%',
    },
    table: {
        minWidth: 500,
    },
    header: {
        padding: 10,
    },
});
export const MatchupSelectionPage = withRouter(
    withStyles(styles, { withTheme: true })(({ location, classes }) => {
        const searchParams = new URLSearchParams(location.search);
        const eid = searchParams.get('eid');
        return (
            <React.Fragment>
                <Matchup eid={eid} />
            </React.Fragment>
        );
    })
);
