import gql from 'graphql-tag';

import { WAGER_INFO } from '../Fragment/Wager';

// note for cache udpate to work, the return here needs to match the original
export const ADD_WAGER_MUTATION = gql`
    mutation addWager($wagerInput: AddWagerInput!) {
        addWager(input: $wagerInput) {
            ...wagerInfo
        }
    }
    ${WAGER_INFO}
`;

export const UPDATE_WAGER = gql`
    mutation UpdateWager($updateWagerInput: UpdateWagerInput!) {
        updateWager(input: $updateWagerInput) {
            id
            amount
            odds
            entered
            picks {
                id
                adjValue
            }
        }
    }
`;

export const DELETE_WAGER = gql`
    mutation DeleteWager($id: Int!) {
        deleteWager(id: $id)
    }
`;

export const UPDATE_ANNOUNCERS = gql`
    mutation updateAnnouncers($year: Int!, $week: Int!) {
        updateAnnouncers(year: $year, week: $week)
    }
`;

export const UPDATE_INJURIES = gql`
    mutation updateInjuries($year: Int!, $week: Int!) {
        updateInjuries(year: $year, week: $week)
    }
`;

export const UPDATE_SCHEDULE_DATA = gql`
    mutation updateScheduleData($year: Int!, $week: Int!) {
        updateScheduleData(year: $year, week: $week)
    }
`;
export const UPDATE_ALL_FOR_WEEK = gql`
    mutation updateAllForWeek($year: Int!, $week: Int!) {
        updateAllForWeek(year: $year, week: $week)
    }
`;
export const UPDATE_SNAPS = gql`
    mutation updateSnaps($year: Int!, $week: Int!) {
        updateSnaps(year: $year, week: $week)
    }
`;
export const UPDATE_DEPTH = gql`
    mutation updateDepth($year: Int!, $week: Int!) {
        updateDepth(year: $year, week: $week)
    }
`;
export const UPDATE_PFR = gql`
    mutation updatePfr($year: Int!, $week: Int!) {
        updatePfr(year: $year, week: $week)
    }
`;

export const UPDATE_WEATHER = gql`
    mutation updateWeather($year: Int!, $week: Int!) {
        updateWeather(year: $year, week: $week)
    }
`;

export const UPDATE_CHANNELS = gql`
    mutation updateChannels($year: Int!, $week: Int!) {
        updateChannels(year: $year, week: $week)
    }
`;

export const UPDATE_TEAMS = gql`
    mutation updateTeams {
        updateTeams
    }
`;

export const UPDATE_GAMES = gql`
    mutation updateGames($year: Int!, $week: Int!) {
        updateGames(year: $year, week: $week)
    }
`;

export const UPDATE_LINES = gql`
    mutation updateLines($year: Int!, $week: Int!) {
        updateLines(year: $year, week: $week)
    }
`;

export const UPDATE_YOUWAGER_LINES = gql`
    mutation updateYouWagerLines($year: Int!, $week: Int!) {
        updateYouWagerLines(year: $year, week: $week)
    }
`;

export const UPDATE_DSI_LINES = gql`
    mutation updateBetDSILines($year: Int!, $week: Int!) {
        updateBetDSILines(year: $year, week: $week)
    }
`;

export const UPDATE_PICK = gql`
    mutation UpdatePick($updateWagerPickInput: UpdateWagerPickInput!) {
        updatePick(input: $updateWagerPickInput) {
            id
            amount
            odds
            entered
            result
            type
            picks {
                id
                adjValue
                result
            }
        }
    }
`;

export const UPDATE_META = gql`
    mutation UpdateMeta($eid: Int!, $tv: String!) {
        updateMeta(eid: $eid, tv: $tv) {
            eid
            channel
            announcers
            date
            matchup
            tv
        }
    }
`;
