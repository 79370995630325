import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MetaUpdater from './Dashboard/MetaUpdater';
import Paper from '@material-ui/core/Paper';
import ReportsMenu from './Dashboard/ReportsMenu';
import {
    TheOddsUpdater,
    YouWagerUpdater,
    TeamsUpdater,
    GamesUpdater,
    AnnouncersUpdater,
    InjuriesUpdater,
    WeatherUpdater,
    AllWeeklyUpdater,
    ScheduleDataUpdater,
    PFRUpdater,
    SnapsUpdater,
    DepthUpdater,
} from './Dashboard/DataSourceUpdater';
import { HeritageUpdater } from './Dashboard/HeritageUpdater';
import { DSIUpdater } from './Dashboard/DSIUpdater';
import { AllSourcesUpdater } from './Dashboard/AllSourcesUpdater';

class Dashboard extends React.Component {
    constructor(props, context) {
        super(props, context);
        // set the initial component state
        this.state = {
            dates: {
                bestdsi: null,
                youwager: null,
                theodds: null,
                heritage: null,
            },
        };
    }

    afterAllUpdate(newDates) {
        this.setState({ dates: { ...newDates } });
    }

    render() {
        const { user } = this.props;
        const { dates } = this.state;
        return (
            <React.Fragment>
                <Paper
                    style={{
                        flexDirection: 'column',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="headline">Dashboard</Typography>
                    <Typography style={{ fontSize: '14px', color: 'green' }}>
                        Welcome <strong>{user.name}</strong>
                    </Typography>
                </Paper>
                <Grid container direction="row">
                    {/* <Grid item>
                    <DataSourceUpdater mutation={UPDATE_TEAMS} type="teams" typeLabel="Teams" />
                </Grid> */}
                    <Grid item>
                        <TheOddsUpdater externalLastUpdate={dates.theodds} />
                    </Grid>

                    <Grid item>
                        <YouWagerUpdater externalLastUpdate={dates.youwager} />
                    </Grid>
                    <Grid item>
                        <DSIUpdater externalLastUpdate={dates.betdsi} />
                    </Grid>
                    <Grid item>
                        <HeritageUpdater externalLastUpdate={dates.heritage} />
                    </Grid>
                    <Grid item>
                        <GamesUpdater />
                    </Grid>
                    <Grid item>
                        <AllWeeklyUpdater />
                    </Grid>
                    <Grid item>
                        <WeatherUpdater />
                    </Grid>
                    <Grid item>
                        <PFRUpdater />
                    </Grid>
                    <Grid item>
                        <ScheduleDataUpdater />
                    </Grid>
                    <Grid item>
                        <SnapsUpdater />
                    </Grid>
                    <Grid item>
                        <DepthUpdater />
                    </Grid>
                    <Grid item>
                        <AnnouncersUpdater />
                    </Grid>
                    <Grid item>
                        <InjuriesUpdater />
                    </Grid>

                    <Grid item>
                        <TeamsUpdater />
                    </Grid>
                    <Grid item>
                        <AllSourcesUpdater afterUpdate={(dates) => this.afterAllUpdate(dates)} />
                    </Grid>
                </Grid>

                <ReportsMenu />
                <Paper style={{ margin: 20, padding: 10 }}>
                    <MetaUpdater />
                </Paper>
            </React.Fragment>
        );
    }
}

export default Dashboard;

{
    /* <Grid item>
<Card style={{ margin: 20 }}>
    <CardContent>
        {user && (
            <Typography style={{ fontSize: '14px', color: 'green' }}>
                Welcome <strong>{user.name}</strong>!<br />
            </Typography>
        )}
    </CardContent>
    <CardContent>test</CardContent>
</Card>
</Grid> */
}
