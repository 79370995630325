import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
//import { Card, CardText } from 'material-ui/Card';
//import TextField from 'material-ui/TextField';

import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const SignUpForm = ({ onSubmit, onChange, errors, user }) => (
    <Card className="container">
        <CardContent>
            <form action="/" onSubmit={onSubmit}>
                <h2 className="card-heading">Sign Up</h2>
                {errors.summary && <p className="error-message">{errors.summary}</p>}
                <div className="field-line">
                    <TextField
                        required
                        label="Name"
                        name="name"
                        errorText={errors.name}
                        onChange={onChange}
                        value={user.name}
                    />
                </div>
                <div className="field-line">
                    <TextField
                        required
                        label="Email"
                        name="email"
                        errorText={errors.email}
                        onChange={onChange}
                        value={user.email}
                    />
                </div>
                <div className="field-line">
                    <TextField
                        required
                        label="Password"
                        type="password"
                        name="password"
                        onChange={onChange}
                        errorText={errors.password}
                        value={user.password}
                    />
                </div>
                <div className="button-line">
                    <Button type="submit" label="Create New Account" color="primary" />
                </div>
                Already have an account? <Link to={'/login'}>Log in</Link>
            </form>
        </CardContent>
    </Card>
);

SignUpForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default SignUpForm;
