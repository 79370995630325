import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardContent';

const styles = (theme) => ({
    imageButton: {
        margin: theme.spacing.unit - 4,
        width: 94,
        minWidth: 'unset',
    },
    imageSrc: {},
});

const BookLogoLinkBase = ({ book, classes }) => {
    return (
        <Button href={book.url} target="_blank" color="secondary" className={classes.imageButton}>
            <img className={classes.imageButton} src={`/images/${book.image}`} />
        </Button>
    );
};

const BookLogoLink = withStyles(styles, { withTheme: true })(BookLogoLinkBase);
export default BookLogoLink;

const logoHeaderStyles = (theme) => ({
    imageButton: {
        width: 52,
        margin: 2,
        padding: 0,
        minHeight: 'unset',
    },
    imageSrc: {
        width: 42,
    },
});
const wagerHeaderStyles = (theme) => ({
    header: {
        //padding:4,
        padding: '4px 10px 0 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

const BookLogoLinkHeader = withStyles(logoHeaderStyles, { withTheme: true })(BookLogoLink);

export const WagerHeader = withStyles(wagerHeaderStyles, { withTheme: true })(
    ({ book, title, classes, children }) => {
        return (
            <CardHeader title={title} className={classes.header}>
                <BookLogoLinkHeader book={book} />
                <div>{title}</div>
                <div>{children}</div>
            </CardHeader>
        );
    }
);
