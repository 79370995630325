import React, { Component } from 'react';
//import { BrowserRouter } from 'react-router-dom'
import './App.css';
import { ApolloProvider } from 'react-apollo';
import Main from './Main';
import Client from '../modules/Client';
// import getMuiTheme from 'material-ui/styles/getMuiTheme';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';

// A theme with custom primary and secondary color.
const theme = createMuiTheme({
    palette: {
        primary: {
            //main: '#303f9f'
            main: '#221266', //deep purple
        },
        secondary: {
            //main: '#4db6ac'
            main: '#00897b', //amber 900
        },
    },
});
//console.log('process', process.env.REACT_APP_GOOGLE_CLIENT_ID);
const AppolloApp = () => (
    <ApolloProvider client={Client}>
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Main />
            </Router>
        </MuiThemeProvider>
    </ApolloProvider>
);

export default AppolloApp;
