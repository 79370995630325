// for grades, use toggle buttons or https://codepen.io/samkeddy/pen/PbROLK -- could build autograde

import React from 'react';
import { withTheme } from '@material-ui/core/styles';

import ToggleChip from '../Util/ToggleChip';
import DateFilterChips from '../Filters/DateFilterChips';

const WagerFilters = withTheme()(({ toggleFilter, wagerFilters, ...rest }) => {
    return (
        <React.Fragment>
            <DateFilterChips toggleFilter={toggleFilter} filters={wagerFilters} {...rest} />
            <ToggleChip
                isOn={wagerFilters.pastGames}
                onLabel="Past"
                name="pastGames"
                offLabel="Show Past"
                onToggle={toggleFilter}
            />
            <ToggleChip
                isOn={wagerFilters.gradedGames}
                name="gradedGames"
                onLabel="Graded"
                offLabel="Show Graded"
                onToggle={toggleFilter}
            />
            <ToggleChip
                isOn={wagerFilters.deadTeasers}
                name="deadTeasers"
                onLabel="Dead Teasers"
                offLabel="Show Dead Teasers"
                onToggle={toggleFilter}
            />
        </React.Fragment>
    );
});

export default WagerFilters;
