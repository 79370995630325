import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import LineValue from '../Util/LineValue';
import classNames from 'classnames';
import orange from '@material-ui/core/colors/orange';

const styles = (theme) => ({
    button: {
        margin: theme.spacing.unit - 4,
        borderRadius: 50,
        height: 52,
        width: 52,
        minWidth: 'unset',
    },
    buttonSelectedWager: {
        color: theme.palette.getContrastText(orange[200]),
        backgroundColor: orange[200],
        '&:hover': {
            backgroundColor: orange[700],
        },
    },
});

export const ButtonSpread = withStyles(styles, { withTheme: true })(
    ({ line, onClick, classes }) => {
        let { hasWager, wagerType } = line;
        let buttonClassAry = [classes.button];
        if (hasWager && wagerType == 'S') {
            buttonClassAry.push(classes.buttonSelectedWager);
        }
        let buttonClasses = classNames(buttonClassAry);
        return (
            <Button onClick={() => onClick(line)} variant="outlined" className={buttonClasses}>
                <div className={classes.innerButton}>
                    <LineValue {...line} />
                    <Typography variant="caption">{line.odds}</Typography>
                </div>
            </Button>
        );
    }
);
